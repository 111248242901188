import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";

class Availability extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      loading: false,
      working_hours: {
        sunday: { open: "yes", from: "09:00 AM", to: "10:00 PM" },
        monday: { open: "yes", from: "09:00 AM", to: "10:00 PM" },
        tuesday: { open: "no", from: "", to: "" },
        wednesday: { open: "yes", from: "09:00 AM", to: "10:00 PM" },
        thursday: { open: "yes", from: "09:00 AM", to: "10:00 PM" },
        friday: { open: "yes", from: "09:00 AM", to: "10:00 PM" },
        saturday: { open: "yes", from: "09:00 AM", to: "10:00 PM" },
      },
    };
  }

  componentDidMount() {
    const userid = window.location.pathname.split("/").pop();
    this.fetchUserData(userid);
  }

  componentDidMount() {
    const userid = window.location.pathname.split("/").pop();
    this.fetchUserData(userid);
  }

  fetchUserData = async (userid) => {
    this.setState({ loading: true });
    const { token } = this.state;
    const { AdminExpertUserDetails } = adminendPoints;

    try {
      const response = await APICaller(
        AdminExpertUserDetails,
        "POST",
        { token, userid },
        "application/json"
      );
      const { status, user } = response.data;

      if (status === 200 && user) {
        this.setState({
          loading: false,
          _id: userid,
        });
      } else {
        this.setState({
          errorMsg: "Failed to fetch user data",
          loading: false,
        });
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      this.setState({ errorMsg: "Failed to fetch user data", loading: false });
    }
  };

  handleWorkingHoursChange = (day, field, value) => {
    this.setState((prevState) => ({
      working_hours: {
        ...prevState.working_hours,
        [day]: {
          ...prevState.working_hours[day],
          [field]: value,
        },
      },
    }));
  };

  handleFormSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    const { token, working_hours, _id } = this.state;
    const { AdminUpdateShopInformation } = adminendPoints;

    try {
      const response = await APICaller(
        AdminUpdateShopInformation,
        "POST",
        { id: _id, token, working_hours },
        "application/json"
      );

      const { status } = response;

      if (status === 200) {
        this.setState({
          successMsg: "Working hours updated successfully.",
          errorMsg: "",
        });
        setTimeout(
          () =>
            this.setState({ successMsg: "", redirect: true, loading: false }),
          5000
        );
      } else {
        this.setState({
          errorMsg: "Error saving working hours.",
          loading: false,
        });
        setTimeout(() => this.setState({ errorMsg: "" }), 3000);
      }
    } catch (error) {
      console.log("Error making data call:", error);
      this.setState({ errorMsg: "Error making data call.", loading: false });
      setTimeout(() => this.setState({ errorMsg: "" }), 3000);
    }
  };

  renderWorkingHours = () => {
    const { working_hours } = this.state;
    const daysOfWeek = Object.keys(working_hours);

    return daysOfWeek.map((day) => {
      const { open, from, to } = working_hours[day];

      return (
        <div key={day} className="col-lg-6">
          <div className="form-group">
            <label htmlFor={`open-${day}`}>
              <input
                type="checkbox"
                id={`open-${day}`}
                checked={open === "yes"}
                onChange={(e) =>
                  this.handleWorkingHoursChange(
                    day,
                    "open",
                    e.target.checked ? "yes" : "no"
                  )
                }
              />{" "}
              {day.charAt(0).toUpperCase() + day.slice(1)}
            </label>

            {open === "yes" ? (
              <>
                <div className="form-group">
                  <label htmlFor={`from-${day}`}>From</label>
                  <input
                    type="text"
                    id={`from-${day}`}
                    value={from}
                    onChange={(e) =>
                      this.handleWorkingHoursChange(day, "from", e.target.value)
                    }
                    className={`form-control ${
                      from === "" ? "is-invalid" : ""
                    }`}
                    placeholder="09:00 AM"
                  />
                  {from === "" && (
                    <div className="invalid-feedback">
                      Please enter a valid start time.
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor={`to-${day}`}>To</label>
                  <input
                    type="text"
                    id={`to-${day}`}
                    value={to}
                    onChange={(e) =>
                      this.handleWorkingHoursChange(day, "to", e.target.value)
                    }
                    className={`form-control ${to === "" ? "is-invalid" : ""}`}
                    placeholder="10:00 PM"
                  />
                  {to === "" && (
                    <div className="invalid-feedback">
                      Please enter a valid end time.
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="form-group">
                <span>Closed</span>
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  render() {
    const { redirect, successMsg, errorMsg, loading, _id } = this.state;

    if (redirect) {
      return <Navigate to={`/EditExpert/${_id}`} />;
    }

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />

          <div className="card mb-4">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <div className="bs-r-6 ps-3">
                  <h4 className="card-title mb-0">Update Profile</h4>
                </div>
                <div className="ms-auto">
                  <Link to={`/EditExpert/${_id}`} className="btn-light1 ms-2">
                    <span>Back</span>
                  </Link>
                </div>
              </div>
              <form
                className="form-area1 row g-4"
                onSubmit={this.handleFormSubmit}
              >
                {errorMsg && (
                  <div className="alert alert-danger" role="alert">
                    {errorMsg}
                  </div>
                )}

                {successMsg && (
                  <div className="alert alert-success" role="alert">
                    {successMsg}
                  </div>
                )}

                {loading && (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}

                {this.renderWorkingHours()}

                <div className="col-12">
                  <button
                    type="submit"
                    className="btn-blue1"
                    disabled={loading}
                  >
                    Update Profile
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Availability;
