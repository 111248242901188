import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import moment from "moment";
import Select from "react-select";

class EditForm extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    this.state = {
      id: "",
      token: retrievedObject ? retrievedObject.token : "",
      selectedStates: [],
      selectedDistricts: [],
      selectedCities: [],
      selectedServices: [],
      selectedSubServices: [],
      selectedExperts: [],
      states: [],
      districts: [],
      cities: [],
      services: [],
      subServices: [],
      experts: [],
      error: null,
      status: "",
      offer_name: "",
      number_of_offers: "",
      startDate: "",
      endDate: "",
      additional_information: "",
      errors: {},
      featured_image: "",
      selectedFile: null,
      previewURL: null,
      originalPrice: "",
      afterDiscountPrice: "",
      loading: true,
      redirect: false,
      successMsg: "",
      errorMsg: "",
    };
  }

  componentDidMount() {
    const id = window.location.pathname.split("/").pop();
    this.getAllUsersResults();
    this.getOfferDetails(id);
  }

  formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Ensure 2-digit format
    const day = String(d.getDate()).padStart(2, "0"); // Ensure 2-digit format
    return `${year}-${month}-${day}`;
  };

  getAllUsersResults = async () => {
    try {
      const { token } = this.state;
      const {
        AdminStateList,
        AdminDistrictList,
        AdminCityList,
        AdminServicesListMobile,
        AdminExpertList,
      } = adminendPoints;

      const method = "GET";
      const contentType = "application/json";
      const urls = {
        stateUrl: `${AdminStateList}`,
        districtUrl: `${AdminDistrictList}`,
        cityUrl: `${AdminCityList}`,
        serviceUrl: `${AdminServicesListMobile}`,
        expertUrl: `${AdminExpertList}`,
      };

      const fetchAllData = async () => {
        const data = {};
        await Promise.all(
          Object.keys(urls).map(async (key) => {
            const response = await APICaller(
              urls[key],
              method,
              "",
              contentType,
              token
            );
            data[key] = response.data;
          })
        );
        return data;
      };

      const { stateUrl, districtUrl, cityUrl, serviceUrl, expertUrl } =
        await fetchAllData();

      this.setState({
        states: stateUrl.states,
        districts: districtUrl.districts,
        cities: cityUrl.citys,
        services: serviceUrl.services,
        experts: expertUrl.expertusers,
      });
    } catch (error) {
      console.log("Error fetching data: ", error);
      this.setState({ errorMsg: "Error fetching data" });
    }
  };

  getOfferDetails = async (id) => {
    try {
      this.setState({ loading: true });
      const { token, services } = this.state;
      const { AdminOfferDetails } = adminendPoints;
      const offerUrl = `${AdminOfferDetails}/${id}`;

      const response = await APICaller(
        offerUrl,
        "GET",
        "",
        "application/json",
        token
      );
      const offerDetails = response.data.offer;

      if (services.length === 0) {
        await this.getAllUsersResults();
      }

      const updatedServices = this.state.services;
      const updatedExperts = this.state.experts;
      const selectedStates = offerDetails.state.map((state) => ({
        value: state.state_id,
        label: state.state_name,
      }));

      const selectedDistricts = offerDetails.district.map((district) => ({
        value: district.district_id,
        label: district.district_name,
      }));

      const selectedCities = offerDetails.city.map((city) => ({
        value: city.city_id,
        label: city.city_name,
      }));

      const selectedService = updatedServices.find(
        (service) => service._id === offerDetails.service.service_id
      );

      const selectedServices = {
        value: offerDetails.service.service_id,
        label: selectedService
          ? selectedService.service_name
          : "Unknown Service",
      };

      const selectedSubServices = {
        value: offerDetails.sub_services.sub_service_id._id,
        label: offerDetails.sub_services.sub_service_id.sub_service_name,
        price: offerDetails.sub_services.price,
      };

      const selectedExperts = offerDetails.expert_id
        ? [
            {
              value: offerDetails.expert_id,
              label: offerDetails.expertDetails.name,
            },
          ]
        : [];

      this.setState({
        selectedStates,
        selectedDistricts,
        selectedCities,
        selectedServices,
        selectedSubServices,
        selectedExperts,
        id: offerDetails._id || "",
        offerName: offerDetails.offer_name || "",
        numberOfOffers: offerDetails.number_of_offers || "",
        startDate: offerDetails.start_date
          ? this.formatDate(offerDetails.start_date)
          : "",
        endDate: offerDetails.end_date
          ? this.formatDate(offerDetails.end_date)
          : "",
        additional_information: offerDetails.additional_information || "",
        number_of_offers: offerDetails.number_of_offers || "",
        discount: offerDetails.discount || "",
        originalPrice: offerDetails.originalPrice || "",
        afterDiscountPrice: offerDetails.afterDiscountPrice || "",
        status: offerDetails.status || "In-Active",
        loading: false,
      });
    } catch (error) {
      console.log("Error fetching offer details: ", error);
      this.setState({ error: "Error fetching offer details" });
    } finally {
      // Stop loader
      this.setState({ loading: false });
    }
  };

  handleServiceChange = async (selectedOptions) => {
    this.setState({ selectedServices: selectedOptions });

    const selectedServiceIds = selectedOptions.map((service) => service.value);

    const subServices = await this.fetchSubServices(selectedServiceIds);

    this.setState({
      subServices,
      selectedSubServices: [],
    });
  };

  handleStateChange = (selectedOptions) => {
    this.setState({
      selectedStates: selectedOptions,
      selectedDistricts: [],
      selectedCities: [],
    });
  };

  handleDistrictChange = (selectedOptions) => {
    this.setState({ selectedDistricts: selectedOptions, selectedCities: [] });
  };

  handleCityChange = (selectedOptions) => {
    this.setState({ selectedCities: selectedOptions });
  };

  handleExpertChange = (selectedOptions) => {
    this.setState({ selectedExperts: selectedOptions });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleFileSelect = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        selectedFile: file,
        previewURL: reader.result,
        fileName: file.name,
      });
    };
    reader.readAsDataURL(file);
  };

  handleFileRemove = () => {
    document.getElementById("fileName").value = "";

    this.setState({
      selectedFile: null,
      previewURL: null,
      fileName: "",
    });
  };

  validateForm = () => {
    const {
      offerName,
      number_of_offers,
      startDate,
      endDate,
      status,
      discount,
      selectedExperts,
      selectedFile,
      selectedServices,
      selectedSubServices,
      selectedStates,
      selectedDistricts,
      selectedCities,
      originalPrice,
      afterDiscountPrice,
    } = this.state;

    const newErrors = {};

    if (!offerName || offerName.trim() === "") {
      newErrors.offerName = "Offer Name is required.";
    }

    if (!number_of_offers || isNaN(number_of_offers) || number_of_offers <= 0) {
      newErrors.number_of_offers =
        "Number of offers must be a positive number.";
    }

    if (!startDate) {
      newErrors.startDate = "Start date is required.";
    }

    if (!endDate) {
      newErrors.endDate = "End date is required.";
    } else if (new Date(startDate) > new Date(endDate)) {
      newErrors.endDate = "End date cannot be earlier than start date.";
    }

    if (!status || status.trim() === "") {
      newErrors.status = "Status is required.";
    }

    if (!discount || isNaN(discount) || discount <= 0) {
      newErrors.discount = "Discount must be a positive number.";
    }

    if (!selectedExperts || selectedExperts.length === 0) {
      newErrors.selectedExperts = "At least one expert is required.";
    }

    if (!selectedFile || !selectedFile.name) {
      newErrors.selectedFile = "A featured image is required.";
    }

    if (!selectedServices || selectedServices.length === 0) {
      newErrors.selectedServices = "At least one service must be selected.";
    }

    if (!selectedSubServices || selectedSubServices.length === 0) {
      newErrors.selectedSubServices =
        "At least one sub-service must be selected.";
    }

    if (!selectedStates || selectedStates.length === 0) {
      newErrors.selectedStates = "At least one state must be selected.";
    }

    if (!selectedDistricts || selectedDistricts.length === 0) {
      newErrors.selectedDistricts = "At least one district must be selected.";
    }

    if (!selectedCities || selectedCities.length === 0) {
      newErrors.selectedCities = "At least one city must be selected.";
    }

    if (!originalPrice || isNaN(originalPrice) || originalPrice <= 0) {
      newErrors.originalPrice = "Original price must be a positive number.";
    }

    if (
      !afterDiscountPrice ||
      isNaN(afterDiscountPrice) ||
      afterDiscountPrice <= 0
    ) {
      newErrors.afterDiscountPrice =
        "Price after discount must be a positive number.";
    } else if (afterDiscountPrice >= originalPrice) {
      newErrors.afterDiscountPrice =
        "Price after discount must be less than the original price.";
    }

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        window.setTimeout(() => {
          this.setState({ errors: {} });
        }, 5000);
      });
    } else {
      const {
        id,
        offerName,
        number_of_offers,
        startDate,
        endDate,
        additional_information,
        status,
        discount,
        selectedExperts,
        selectedFile,
        selectedServices,
        selectedSubServices,
        selectedStates,
        selectedDistricts,
        selectedCities,
        originalPrice,
        afterDiscountPrice,
        token,
      } = this.state;

      const expert_id = selectedExperts[0]?.value || null;
      const service = {
        service_id: selectedServices?.value || null,
        service_name: selectedServices?.label || "",
      };

      const selectedSubService = selectedSubServices;
      const sub_services = {
        sub_service_id: selectedSubService?.value || null,
        price: selectedSubService?.price || "0",
      };

      const state = selectedStates.map((state) => ({
        state_id: state.value,
        state_name: state.label,
      }));

      const district = selectedDistricts.map((district) => ({
        district_id: district.value,
        district_name: district.label,
      }));

      const city = selectedCities.map((city) => ({
        city_id: city.value,
        city_name: city.label,
      }));

      const { AdminOfferUpdate } = adminendPoints;
      const formData = new FormData();
      formData.append("id", id);
      formData.append("offer_name", offerName);
      formData.append("expert_id", expert_id);
      formData.append("featured_image", selectedFile);
      formData.append("number_of_offers", number_of_offers);
      formData.append("startDate", startDate);
      formData.append("endDate", endDate);
      formData.append("discount", discount);
      formData.append("additional_information", additional_information);
      formData.append("status", status);
      formData.append("originalPrice", originalPrice);
      formData.append("afterDiscountPrice", afterDiscountPrice);
      formData.append("service", JSON.stringify(service));
      formData.append("sub_services", JSON.stringify(sub_services));
      formData.append("state", JSON.stringify(state));
      formData.append("district", JSON.stringify(district));
      formData.append("city", JSON.stringify(city));

      const method = "POST";
      const body = formData;

      try {
        const response = await APICaller(
          AdminOfferUpdate,
          method,
          body,
          {},
          token
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsg: "Offer updated successfully.",
              errorMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
              }, 5000);
            }
          );
        } else if (status === 201) {
          this.setState(
            {
              errorMsg: "This Banner Name is already exist",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to create Banner Name. Please try again.",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the Banner Name. Please try again.",
          successMsg: "",
        });
      }
    }
  };

  render() {
    const {
      selectedStates,
      selectedDistricts,
      selectedCities,
      states,
      districts,
      cities,
      token,
      services,
      selectedServices,
      subServices,
      selectedExperts,
      experts,
      offerName,
      number_of_offers,
      startDate,
      endDate,
      additional_information,
      errors,
      discount,
      previewURL,
      originalPrice,
      afterDiscountPrice,
      loading,
      successMsg,
      errorMsg,
      redirect,
    } = this.state;

    if (redirect) {
      return <Navigate to="/ManageOffers" />;
    }

    if (token === "") {
      return <Navigate to="/" />;
    }

    const serviceOptions = services.map((service) => ({
      value: service._id,
      label: service.service_name,
    }));

    const subServiceOptions = subServices.map((subService) => ({
      value: subService.value,
      label: subService.label,
    }));

    const expertOptions = experts.map((expert) => ({
      value: expert._id,
      label: expert.name,
    }));

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="card mb-4">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <div className="bs-r-6 ps-3">
                  <h4 className="card-title mb-0">Edit Offer Information</h4>
                </div>
                <div className="ms-auto">
                  <Link to="/ManageOffers" className="link">
                    <img
                      className="me-2"
                      src="assets/img/icon-edit1.svg"
                      alt=""
                      title=""
                    />
                    <span>Back</span>
                  </Link>
                </div>
              </div>
              {loading && (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}

              {errorMsg && (
                <div className="alert alert-danger" role="alert">
                  {errorMsg}
                </div>
              )}

              {successMsg && (
                <div className="alert alert-success" role="alert">
                  {successMsg}
                </div>
              )}
              <form className="form-area1 row g-4" onSubmit={this.handleSubmit}>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Select Service</label>
                    <Select
                      id="service"
                      options={serviceOptions}
                      value={selectedServices}
                      isMulti
                      onChange={this.handleServiceChange}
                      isDisabled
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <label htmlFor="service">Sub Service</label>
                  <Select
                    id="subServices"
                    options={subServiceOptions}
                    isMulti
                    value={this.state.selectedSubServices}
                    onChange={(selectedOptions) =>
                      this.setState({ selectedSubServices: selectedOptions })
                    }
                    isDisabled
                  />
                </div>
                <div className="col-lg-6 d-none">
                  <div className="form-group">
                    <label htmlFor="status">Select State</label>
                    <Select
                      value={selectedStates}
                      onChange={this.handleStateChange}
                      options={states.map((state) => ({
                        value: state._id,
                        label: state.state_name,
                      }))}
                      isMulti
                      isDisabled
                    />
                  </div>
                </div>
                <div className="col-lg-6 d-none">
                  <div className="form-group">
                    <label htmlFor="status">Select District</label>
                    <Select
                      value={selectedDistricts}
                      onChange={this.handleDistrictChange}
                      options={districts.map((district) => ({
                        value: district._id,
                        label: district.district_name,
                      }))}
                      isMulti
                      isDisabled
                    />
                  </div>
                </div>
                <div className="col-lg-6 d-none">
                  <div className="form-group">
                    <label htmlFor="status">Select City</label>
                    <Select
                      value={selectedCities}
                      onChange={this.handleCityChange}
                      options={cities.map((city) => ({
                        value: city._id,
                        label: city.city_name,
                      }))}
                      isMulti
                      isDisabled
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <label htmlFor="status">Select Expert</label>
                    <Select
                      value={selectedExperts}
                      onChange={this.handleExpertChange}
                      options={expertOptions}
                      isMulti
                      isDisabled
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <label htmlFor="offer_name">Offer Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="offer_name"
                    placeholder="Offer Name"
                    name="offer_name"
                    onChange={this.handleInputChange}
                    value={offerName}
                    readOnly
                  />
                  {errors.offer_name && (
                    <p className="error">{errors.offer_name}</p>
                  )}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="startDate">Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="startDate"
                    placeholder="Start Date"
                    name="startDate"
                    value={startDate}
                    onChange={this.handleInputChange}
                    readOnly
                  />
                  {errors.startDate && (
                    <p className="error">{errors.startDate}</p>
                  )}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="startDate">End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="endDate"
                    placeholder="End Date"
                    name="endDate"
                    value={endDate}
                    onChange={this.handleInputChange}
                    readOnly
                  />
                  {errors.endDate && <p className="error">{errors.endDate}</p>}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="number_of_offers"> Number of Offers </label>
                  <input
                    type="text"
                    className="form-control"
                    id="number_of_offers"
                    placeholder="Number of Offers"
                    name="number_of_offers"
                    value={number_of_offers}
                    onChange={this.handleInputChange}
                    readOnly
                  />
                  {errors.number_of_offers && (
                    <p className="error">{errors.number_of_offers}</p>
                  )}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="discount">Discount</label>
                  <input
                    type="text"
                    className="form-control"
                    id="discount"
                    placeholder="Discount"
                    name="discount"
                    value={discount}
                    onChange={this.handleInputChange}
                    readOnly
                  />
                  {errors.discount && (
                    <p className="error">{errors.discount}</p>
                  )}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="originalPrice">Original Price</label>
                  <input
                    type="text"
                    className="form-control"
                    id="originalPrice"
                    placeholder="Original Price"
                    name="originalPrice"
                    value={originalPrice}
                    onChange={this.handleInputChange}
                    readOnly
                  />
                  {errors.originalPrice && (
                    <p className="error">{errors.originalPrice}</p>
                  )}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="afterDiscountPrice">
                    After Discount Price
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="afterDiscountPrice"
                    placeholder="After Discount Price"
                    name="afterDiscountPrice"
                    value={afterDiscountPrice}
                    onChange={this.handleInputChange}
                    readOnly
                  />
                  {errors.afterDiscountPrice && (
                    <p className="error">{errors.afterDiscountPrice}</p>
                  )}
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="additional_information">
                      Additional Information
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="additional_information"
                      placeholder="Additional Information"
                      name="additional_information"
                      value={additional_information}
                      onChange={this.handleInputChange}
                      readOnly
                    />
                    {errors.additional_information && (
                      <p className="error">{errors.additional_information}</p>
                    )}
                  </div>
                </div>

                <div className="col">
                  <div className="upload-img-box">
                    <i className="fa-solid fa-file-arrow-up"></i>
                    <p className="mb-0">Upload Image</p>
                    <input
                      type="file"
                      className="form-control"
                      id="fileName"
                      placeholder="Absence through"
                      name="fileName"
                      accept="image/*"
                      onChange={this.handleFileSelect}
                    />
                    {previewURL && (
                      <div>
                        <img
                          src={previewURL}
                          alt="Category Preview"
                          style={{ width: "100px", height: "100px" }}
                        />
                        <button type="button" onClick={this.handleFileRemove}>
                          Remove
                        </button>
                      </div>
                    )}
                    {errors.selectedFile && (
                      <p className="error">{errors.selectedFile}</p>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <button type="submit" className="btn-blue1">
                    Update Changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default EditForm;
