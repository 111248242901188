import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate } from "../../utils/commonFunction";

export default class Home extends Component {
  constructor(props) {
    super(props);

    var retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      token: retrievedObject ? retrievedObject.token : "",
      results: "",
      stylistResults: [],
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getCityWiseMaleFeMale();
    this.getStylistsStat();
  }

  getStylistsStat = () => {
    const { token, currentPage, itemsPerPage } = this.state;
    const { AdminGetStylistsStats } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    let queryParams = `page=${currentPage}&limit=${itemsPerPage}`;

    const url = `${AdminGetStylistsStats}${
      queryParams ? `?${queryParams}` : ""
    }`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, totalItems, stylistStats } = response.data;

        if (status === 200) {
          this.setState({
            totalItems: totalItems,
            stylistResults: stylistStats,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  getCityWiseMaleFeMale = async () => {
    const { token } = this.state;
    const { AdminGetCityStylistSummary } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      const body = {};
      const response = await APICaller(
        AdminGetCityStylistSummary,
        method,
        body,
        contentType,
        token
      );

      const { status, data } = response.data;

      if (status === 200) {
        this.setState({
          results: data,
        });
      }
    } catch (error) {
      console.log("error making data call --> ", error);
      this.setState({
        errorMsg:
          "An error occurred while fetching users by category. Please try again.",
        successMsg: "",
      });
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      this.getStylistsStat();
    });
  };

  render() {
    const {
      token,
      stylistResults,
      currentPage,
      itemsPerPage,
      totalItems,
      isLoading,
      results,
    } = this.state;

    if (!token) {
      return <Navigate to="/" />;
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItems = stylistResults.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    const totalPages =
      totalItems > 0 ? Math.ceil(totalItems / itemsPerPage) : 1;

    // Loading state
    if (isLoading) {
      return <div>Loading...</div>;
    }

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="main-right-content-inner">
            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <h4 className="card-title mb-0">Dashboard</h4>
                  <div className="ms-auto">
                    <button type="button" className="btn-light ms-2">
                      <img
                        className="me-2"
                        src="assets/img/icon-calendar.svg"
                        alt=""
                      />
                      January
                    </button>
                    <button type="button" className="btn-light ms-2">
                      <img
                        className="me-2"
                        src="assets/img/icon-filter.svg"
                        alt=""
                      />
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex align-items-center pb-20">
                  <h4 className="card-title mb-0">City Statistics</h4>
                  <div className="ms-auto">
                    <button type="button" className="apply-btn">
                      Apply
                    </button>
                  </div>
                </div>

                <div className="d-flex all-div">
                  {results.length > 0 ? (
                    results.map((cityStat, index) => (
                      <div className="cities" key={index}>
                        <table className="w-100">
                          <tbody>
                            <tr>
                              <td>
                                <h2>{cityStat.city}</h2>
                              </td>
                              <td>
                                <label className="custom-checkbox">
                                  <input type="checkbox" />
                                  <span></span>
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>Male Stylist</p>
                              </td>
                              <td>
                                <p>{cityStat.maleStylistCount || 0}</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>Female Stylist</p>
                              </td>
                              <td>
                                <p>{cityStat.femaleStylistCount || 0}</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>Total Stylist</p>
                              </td>
                              <td>
                                <p>{cityStat.totalStylistCount || 0}</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ))
                  ) : (
                    <p>No data available</p>
                  )}
                </div>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="bs-r-6 ps-3">
                    <h4 className="card-title mb-0">Top Stylist</h4>
                  </div>
                  <div className="ms-auto">
                    <button type="button" className="btn-light ms-2">
                      <img
                        className="me-2"
                        src="assets/img/icon-filter.svg"
                        alt=""
                      />
                      Filter
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                 <div className="table-responsive" style={{ overflowX: 'auto' }}>
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Stylist Name</th>
                        <th>Joining Date</th>
                        <th>Gender</th>
                        <th>At Salon Booking</th>
                        <th>At Home Booking</th>
                        <th>Star Rating</th>
                        <th>No. Of Review</th>
                        <th>Offer Created</th>
                        <th>Offer Booked</th>
                        <th>Package Created</th>
                        <th>Package Booked</th>
                        <th>Campaign Created</th>
                        <th>Campaign Booked</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stylistResults.length === 0 ? (
                        <tr>
                          <td colSpan="14" className="text-center">
                            No stylist data available
                          </td>
                        </tr>
                      ) : (
                        stylistResults.map((stylist, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{stylist.stylistName || "N/A"}</td>
                            <td>
                              {stylist.joiningDate
                                ? new Date(
                                    stylist.joiningDate
                                  ).toLocaleDateString()
                                : "N/A"}
                            </td>
                            <td>{stylist.gender || "N/A"}</td>
                            <td>{stylist.atSalonBooking || "N/A"}</td>
                            <td>{stylist.atHomeBooking || "N/A"}</td>
                            <td>{stylist.starRating || "N/A"}</td>
                            <td>{stylist.numberOfReviews || "N/A"}</td>
                            <td>{stylist.offerCreated || "N/A"}</td>
                            <td>{stylist.offerBooked || "N/A"}</td>
                            <td>{stylist.packageCreated || "N/A"}</td>
                            <td>{stylist.packageBooked || "N/A"}</td>
                            <td>{stylist.campaignCreated || "N/A"}</td>
                            <td>{stylist.campaignBooked || "N/A"}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>

                  <div className="d-flex align-items-center justify-content-end p-3">
                    {currentPage > 1 && (
                      <button
                        className="pagination-prev me-2"
                        onClick={(e) =>
                          this.handlePageChange(currentPage - 1, e)
                        }
                      >
                        <i className="fa-solid fa-angle-left"></i>
                      </button>
                    )}

                    <ul className="pagination m-0">
                      {Array.from({ length: totalPages }).map((_, index) => (
                        <li
                          key={index}
                          className={`page-item ${
                            currentPage === index + 1 ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={(e) => this.handlePageChange(index + 1, e)}
                          >
                            {index + 1}
                          </button>
                        </li>
                      ))}
                    </ul>

                    {currentPage < totalPages && (
                      <button
                        className="pagination-next"
                        onClick={(e) =>
                          this.handlePageChange(currentPage + 1, e)
                        }
                      >
                        <i className="fa-solid fa-angle-right"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
