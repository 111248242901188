import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import moment from "moment";
import Select from "react-select";

class AddOfferForm extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    this.state = {
      token: retrievedObject ? retrievedObject.token : "",
      selectedServices: [],
      selectedSubServices: [],
      selectedStates: [],
      selectedDistricts: [],
      selectedCities: [],
      selectedExperts: [],
      states: [],
      districts: [],
      cities: [],
      services: [],
      subServices: [],
      experts: [],
      successMsg: "",
      errorMsg: "",
      offer_name: "",
      number_of_offers: "",
      startDate: "",
      endDate: "",
      additional_information: "",
      featured_image: "",
      selectedFile: null,
      previewURL: null,
      status: "In-Active",
      discount: "",
      errors: {}, // Initialize errors object here
    };
  }

  componentDidMount() {
    this.getAllUsersResults();
  }

  getAllUsersResults = async () => {
    try {
      const { token } = this.state;
      const {
        AdminStateList,
        AdminDistrictList,
        AdminCityList,
        AdminServicesListMobile,
        AdminExpertList,
      } = adminendPoints;

      const method = "GET";
      const contentType = "application/json";
      const urls = {
        stateUrl: `${AdminStateList}`,
        districtUrl: `${AdminDistrictList}`,
        cityUrl: `${AdminCityList}`,
        serviceUrl: `${AdminServicesListMobile}`,
        expertUrl: `${AdminExpertList}`,
      };

      const fetchAllData = async () => {
        const data = {};
        await Promise.all(
          Object.keys(urls).map(async (key) => {
            const response = await APICaller(
              urls[key],
              method,
              "",
              contentType,
              token
            );
            data[key] = response.data;
          })
        );
        return data;
      };

      const { stateUrl, districtUrl, cityUrl, serviceUrl, expertUrl } =
        await fetchAllData();

      this.setState({
        states: stateUrl.states,
        districts: districtUrl.districts,
        cities: cityUrl.citys,
        services: serviceUrl.services,
        experts: expertUrl.expertusers,
      });
    } catch (error) {
      console.log("error making data call --> ", error);
      this.setState({ errorMsg: "Error fetching data" });
    }
  };

  handleServiceChange = async (selectedOptions) => {
    this.setState({ selectedServices: selectedOptions });

    const selectedServiceIds = selectedOptions.map((service) => service.value);

    try {
      const { token } = this.state;
      const { AdminSubServicesListMobile } = adminendPoints;
      const method = "POST";
      const contentType = "application/json";

      const response = await APICaller(
        AdminSubServicesListMobile,
        method,
        { serviceIds: selectedServiceIds.join(",") },
        contentType,
        token
      );

      const { status, subServices } = response.data;

      if (status === 200) {
        this.setState({
          subServices: (subServices || []).map((subService) => ({
            value: subService._id,
            label: subService.sub_service_name,
          })),
          selectedSubServices: [],
        });
      } else {
        // Handle error if needed
      }
    } catch (error) {
      console.log("error fetching sub-services --> ", error);
    }
  };

  handleStateChange = (selectedOptions) => {
    this.setState({
      selectedStates: selectedOptions,
      selectedDistricts: [],
      selectedCities: [],
    });
  };

  handleDistrictChange = (selectedOptions) => {
    this.setState({ selectedDistricts: selectedOptions, selectedCities: [] });
  };

  handleCityChange = (selectedOptions) => {
    this.setState({ selectedCities: selectedOptions });
  };

  handleExpertChange = (selectedOptions) => {
    this.setState({ selectedExperts: selectedOptions });
  };

  validateForm = () => {
    const {
      offer_name,
      number_of_offers,
      startDate,
      endDate,
      additional_information,
      status,
      discount,
      selectedExperts,
      selectedFile,
      selectedServices,
      selectedSubServices,
      selectedStates,
      selectedDistricts,
      selectedCities,
    } = this.state;

    const newErrors = {};

    if (!offer_name || offer_name.trim() === "") {
      newErrors.offer_name = "Offer Name is required.";
    }

    if (!number_of_offers || isNaN(number_of_offers) || number_of_offers <= 0) {
      newErrors.number_of_offers =
        "Number of offers must be a positive number.";
    }

    if (!startDate) {
      newErrors.startDate = "Start date is required.";
    }

    if (!endDate) {
      newErrors.endDate = "End date is required.";
    } else if (new Date(startDate) > new Date(endDate)) {
      newErrors.endDate = "End date cannot be earlier than start date.";
    }

    if (!status || status.trim() === "") {
      newErrors.status = "Status is required.";
    }

    if (!discount || isNaN(discount) || discount <= 0) {
      newErrors.discount = "Discount must be a positive number.";
    }

    if (!selectedExperts || !selectedExperts.length) {
      newErrors.selectedExperts = "At least one expert is required.";
    }

    if (!selectedFile || !selectedFile.name) {
      newErrors.selectedFile = "A featured image is required.";
    }

    if (!selectedServices || !selectedServices.length) {
      newErrors.selectedServices = "At least one service must be selected.";
    }

    if (!selectedSubServices || !selectedSubServices.length) {
      newErrors.selectedSubServices =
        "At least one sub-service must be selected.";
    }

    if (!selectedStates || !selectedStates.length) {
      newErrors.selectedStates = "At least one state must be selected.";
    }

    if (!selectedDistricts || !selectedDistricts.length) {
      newErrors.selectedDistricts = "At least one district must be selected.";
    }

    if (!selectedCities || !selectedCities.length) {
      newErrors.selectedCities = "At least one city must be selected.";
    }

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form
    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      // Set errors and clear them after 5 seconds
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        window.setTimeout(() => {
          this.setState({ errors: {} });
        }, 5000);
      });
    } else {
      // Clear errors and prepare data for submission
      this.setState({ errors: {} });

      const {
        offer_name,
        number_of_offers,
        startDate,
        endDate,
        additional_information,
        status,
        discount,
        selectedExperts,
        selectedFile,
        selectedServices,
        selectedSubServices,
        selectedStates,
        selectedDistricts,
        selectedCities,
      } = this.state;

      console.log("selectedSubServices:", selectedSubServices);

      // Extract the necessary data dynamically
      const expert_id = selectedExperts[0]?.value || null; // Assuming only one expert is selected
      const service = {
        service_id: selectedServices[0]?.value || null, // Assuming only one service is selected
        service_name: selectedServices[0]?.label || "",
      };

      // Fetch the dynamic price for the selected sub-service
      const selectedSubService = selectedSubServices[0]; // Assuming only one sub-service is selected
      const sub_services = {
        sub_service_id: selectedSubService?.value || null,
        price: selectedSubService?.price || "0", // Dynamic price fetched from selectedSubServices
      };

      // Calculate afterDiscountPrice based on sub_service price and discount
      const originalPrice = parseFloat(sub_services.price);
      const discountAmount = (originalPrice * discount) / 100;
      const afterDiscountPrice = (originalPrice - discountAmount).toFixed(2); // Rounded to 2 decimal places

      const featured_image = `offers//${selectedFile?.name || "default.png"}`; // Use file name from selectedFile

      // Map states, districts, and cities to the expected structure
      const state = selectedStates.map((state) => ({
        state_id: state.value,
        state_name: state.label,
      }));

      const district = selectedDistricts.map((district) => ({
        district_id: district.value,
        district_name: district.label,
      }));

      const city = selectedCities.map((city) => ({
        city_id: city.value,
        city_name: city.label,
      }));

      // Prepare the payload
      const payload = {
        expert_id,
        offer_name,
        service,
        sub_services,
        number_of_offers,
        start_date: startDate,
        end_date: endDate,
        discount,
        additional_information,
        featured_image,
        status,
        state,
        district,
        city,
        originalPrice: originalPrice.toFixed(2), // Include original price
        afterDiscountPrice, // Include discounted price
      };

      console.log("Payload:", payload);

      // Perform API submission here (e.g., using axios or fetch)
      // Example:
      // await apiService.submitOffer(payload).then(response => {
      //   this.setState({ successMsg: "Offer created successfully!", errorMsg: "" });
      // }).catch(error => {
      //   this.setState({ errorMsg: "Failed to create offer.", successMsg: "" });
      // });
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleFileSelect = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        selectedFile: file,
        previewURL: reader.result,
        fileName: file.name,
      });
    };
    reader.readAsDataURL(file);
  };

  handleFileRemove = () => {
    document.getElementById("fileName").value = "";

    this.setState({
      selectedFile: null,
      previewURL: null,
      fileName: "",
    });
  };

  render() {
    const {
      selectedServices,
      selectedSubServices,
      selectedStates,
      selectedDistricts,
      selectedCities,
      selectedExperts,
      states,
      districts,
      cities,
      services,
      subServices,
      experts,
      successMsg,
      errorMsg,
      token,
      offer_name,
      startDate,
      endDate,
      number_of_offers,
      discount,
      additional_information,
      previewURL,
      errors,
    } = this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    const serviceOptions = services.map((service) => ({
      value: service._id,
      label: service.service_name,
    }));

    const subServiceOptions = subServices.map((subService) => ({
      value: subService.value,
      label: subService.label,
    }));

    const expertOptions = experts.map((expert) => ({
      value: expert._id,
      label: expert.name,
    }));

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />

          <div className="card mb-4">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <div className="bs-r-6 ps-3">
                  <h4 className="card-title mb-0">Add Offer Information</h4>
                </div>
                <div className="ms-auto">
                  <Link to="/ManageOffers" className="link">
                    <span>Back</span>
                  </Link>
                </div>
              </div>
              <form className="form-area1 row g-4" onSubmit={this.handleSubmit}>
                {errorMsg && (
                  <div className="alert alert-danger alert-dismissible">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-hidden="true"
                    >
                      &times;
                    </button>
                    <h5>
                      <i className="icon fas fa-ban"></i> Alert!
                    </h5>
                    {errorMsg}
                  </div>
                )}
                {successMsg && (
                  <div className="alert alert-success alert-dismissible">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-hidden="true"
                    >
                      ×
                    </button>
                    <h5>
                      <i className="icon fas fa-check"></i> Alert!
                    </h5>
                    {successMsg}
                  </div>
                )}

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Select Service</label>
                    <Select
                      id="service"
                      options={serviceOptions}
                      value={selectedServices}
                      isMulti
                      onChange={this.handleServiceChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <label htmlFor="service">Sub Service</label>
                  <Select
                    id="subServices"
                    options={subServiceOptions}
                    isMulti
                    value={selectedSubServices}
                    onChange={(selectedOptions) =>
                      this.setState({ selectedSubServices: selectedOptions })
                    }
                  />
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Select State</label>
                    <Select
                      value={selectedStates}
                      onChange={this.handleStateChange}
                      options={states.map((state) => ({
                        value: state._id,
                        label: state.state_name,
                      }))}
                      isMulti
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Select District</label>
                    <Select
                      value={selectedDistricts}
                      onChange={this.handleDistrictChange}
                      options={districts.map((district) => ({
                        value: district._id,
                        label: district.district_name,
                      }))}
                      isMulti
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Select City</label>
                    <Select
                      value={selectedCities}
                      onChange={this.handleCityChange}
                      options={cities.map((city) => ({
                        value: city._id,
                        label: city.city_name,
                      }))}
                      isMulti
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Select Expert</label>
                    <Select
                      value={selectedExperts}
                      onChange={this.handleExpertChange}
                      options={expertOptions}
                      isMulti
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <label htmlFor="offer_name">Offer Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="offer_name"
                    placeholder="Offer Name"
                    name="offer_name"
                    onChange={this.handleInputChange}
                  />
                  {errors.offer_name && (
                    <p className="error">{errors.offer_name}</p>
                  )}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="startDate">Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="startDate"
                    placeholder="Start Date"
                    name="startDate"
                    value={startDate}
                    onChange={this.handleInputChange}
                  />
                  {errors.startDate && (
                    <p className="error">{errors.startDate}</p>
                  )}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="startDate">End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="endDate"
                    placeholder="End Date"
                    name="endDate"
                    value={endDate}
                    onChange={this.handleInputChange}
                  />
                  {errors.endDate && <p className="error">{errors.endDate}</p>}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="number_of_offers"> Number of Offers </label>
                  <input
                    type="text"
                    className="form-control"
                    id="number_of_offers"
                    placeholder="Number of Offers"
                    name="number_of_offers"
                    value={number_of_offers}
                    onChange={this.handleInputChange}
                  />
                  {errors.number_of_offers && (
                    <p className="error">{errors.number_of_offers}</p>
                  )}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="discount">Discount</label>
                  <input
                    type="text"
                    className="form-control"
                    id="discount"
                    placeholder="Discount"
                    name="discount"
                    value={discount}
                    onChange={this.handleInputChange}
                  />
                  {errors.discount && (
                    <p className="error">{errors.discount}</p>
                  )}
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="additional_information">
                      Additional Information
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="additional_information"
                      placeholder="Additional Information"
                      name="additional_information"
                      value={additional_information}
                      onChange={this.handleInputChange}
                    />
                    {errors.additional_information && (
                      <p className="error">{errors.additional_information}</p>
                    )}
                  </div>
                </div>

                <div className="col">
                  <div className="upload-img-box">
                    <i className="fa-solid fa-file-arrow-up"></i>
                    <p className="mb-0">Upload Image</p>
                    <input
                      type="file"
                      className="form-control"
                      id="fileName"
                      placeholder="Absence through"
                      name="fileName"
                      accept="image/*"
                      onChange={this.handleFileSelect}
                    />
                    {previewURL && (
                      <div>
                        <img
                          src={previewURL}
                          alt="Category Preview"
                          style={{ width: "100px", height: "100px" }}
                        />
                        <button type="button" onClick={this.handleFileRemove}>
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <button type="submit" className="btn-blue1">
                    Add Offer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddOfferForm;
