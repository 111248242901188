import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate } from "../../utils/commonFunction";
import { withPermissions } from "../context/PermissionsContext";

class ExpertList extends Component {
  constructor(props) {
    super(props);

    var retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      token: retrievedObject ? retrievedObject.token : "",
      results: [],
      emailErrorMsg: "",
      successMsg: "",
      errorMsg: "",
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      search: "",
      sort: "",
      activeTab: "All",
      loading: true,
      showPopup: false,
      expertPhone: "",
      errors: {},
      successMsgModel: "",
      errorMsgModel: "",
    };
  }

  componentDidMount() {
    this.getExpertResults();
  }

  getExpertResults = () => {
    const { token, currentPage, itemsPerPage, activeTab } = this.state;
    const { AdminExpertList } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    let queryParams = `page=${currentPage}&limit=${itemsPerPage}&status=${activeTab}`;

    const url = `${AdminExpertList}${queryParams ? `?${queryParams}` : ""}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, expertusers, totalItems } = response.data;

        if (status === 200) {
          this.setState({
            results: expertusers,
            totalItems: totalItems,
            loading: false,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
              loading: false,
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
        this.setState({
          loading: false,
        });
      });
  };

  handleToggleCheckBox = async (expertId, currentStatus) => {
    const { token } = this.state;
    const { AdminUpdateStatusExpert } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    const newStatus = currentStatus === "Active" ? "In-Active" : "Active";

    const body = {
      expertId,
      status: newStatus,
    };
    this.setState({ loading: true });

    try {
      const response = await APICaller(
        AdminUpdateStatusExpert,
        method,
        body,
        contentType,
        token
      );

      const { status, message } = response.data;
      if (status === 200) {
        this.setState((prevState) => ({
          results: prevState.results.map((expert) =>
            expert._id === expertId ? { ...expert, status: newStatus } : expert
          ),
          successMsg: `Expert Status is ${newStatus}`,
          errorMsg: "",
          loading: false, // Hide loader after success
        }));
        setTimeout(() => {
          this.setState({ successMsg: "" });
        }, 2000);
        this.getExpertResults();
      } else {
        console.error("Failed to update faq status");
        this.setState({
          errorMsg: message,
          successMsg: "",
          loading: false, // Hide loader after success
        });
      }
    } catch (error) {
      console.error("Error making data call:", error);
      this.setState({
        errorMsg: "An error occurred while updating offer status.",
        successMsg: "",
        loading: false, // Hide loader after success
      });
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      this.getExpertResults();
    });
  };

  handleTabClick = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab, currentPage: 1, loading: true }, () => {
        this.getExpertResults();
      });
    }
  };

  handleSearchChange = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  handleSortChange = (newSort) => {
    this.setState({ sort: newSort, loading: true }, () => {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 400);
    });
  };

  validateForm = () => {
    const errors = {};
    const { expertPhone } = this.state;

    if (!expertPhone) {
      errors.expertPhone = "Phone number is required.";
    } else if (!/^\d{10}$/.test(expertPhone)) {
      errors.expertPhone = "Phone number must be 10 digits.";
    }

    return errors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errors: newErrors });
      setTimeout(() => {
        this.setState({ errors: {} });
      }, 5000);
    } else {
      this.setState({ errors: {} });

      const { expertPhone } = this.state;

      const { AdminExpertRegister } = adminendPoints;
      const method = "POST";
      const body = { phone: expertPhone };
      const contentType = "application/json";

      try {
        const response = await APICaller(
          AdminExpertRegister,
          method,
          body,
          contentType,
          ""
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsgModel: "Stylist created successfully.",
              errorMsgModel: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsgModel: "", redirect: true });
                this.togglePopup(); // Hide the modal
              }, 5000);
            }
          );
        } else if (status === 409) {
          this.setState(
            {
              errorMsgModel: "This phone number is already registered.",
              successMsgModel: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsgModel: "", redirect: false });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsgModel: "Failed to create the expert. Please try again.",
              successMsgModel: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsgModel: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("Error making data call --> ", error);
        this.setState({
          errorMsgModel:
            "An error occurred while creating the expert. Please try again.",
          successMsgModel: "",
        });
      }
    }
  };

  togglePopup = () => {
    this.setState((prevState) => ({
      showPopup: !prevState.showPopup,
      expertPhone: "",
      errors: {},
    }));
  };

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({ [id]: value });
  };

  render() {
    const {
      results,
      token,
      search,
      currentPage,
      itemsPerPage,
      activeTab,
      sort,
      isVisibleView,
      isVisibleAdd,
      totalItems,
      successMsg,
      errorMsg,
      loading,
      showPopup,
      errors,
      expertPhone,
      successMsgModel,
      errorMsgModel,
    } = this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const filteredAndSortedItems = Array.isArray(results)
      ? [...results]
          .filter((user) => {
            const matchesSearch =
              user.name?.toLowerCase().includes(search.toLowerCase()) ||
              user.phone.toLowerCase().includes(search.toLowerCase());

            return matchesSearch;
          })
          .sort((a, b) => {
            return sort === "asc"
              ? a._id.localeCompare(b._id)
              : b._id.localeCompare(a._id);
          })
      : [];

    const currentItems = filteredAndSortedItems.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const { permissions } = this.props;
    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="main-right-content-inner">
            <div className="card mb-4">
              <div className="card-body pb-0">
                <div className="d-flex align-items-center mb-3">
                  <div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">Home</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Manage Stylist's
                      </li>
                    </ol>
                    <h4 className="card-title mb-0">Manage Stylist's</h4>
                  </div>
                  <div className="ms-auto">
                    <button
                      type="button"
                      className="btn-blue ms-2"
                      onClick={this.togglePopup}
                    >
                      <i className="fa-solid fa-plus me-2"></i>Add New
                    </button>
                    <button type="button" className="btn-light1 ms-2">
                      <i className="fa-solid fa-file-export me-2"></i>View
                      Stylist
                    </button>
                  </div>
                </div>
                <div className="tabs1 d-flex">
                  <Link
                    onClick={() => this.handleTabClick("All")}
                    className={activeTab === "All" ? "active" : ""}
                  >
                    All
                  </Link>
                  <Link
                    onClick={() => this.handleTabClick("Active")}
                    className={activeTab === "Active" ? "active" : ""}
                  >
                    Active
                  </Link>
                  <Link
                    onClick={() => this.handleTabClick("In-Active")}
                    className={activeTab === "In-Active" ? "active" : ""}
                  >
                    In-Active
                  </Link>
                </div>
              </div>
            </div>

            {loading && (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}

            {successMsg && (
              <div className="alert alert-success" role="alert">
                {successMsg}
              </div>
            )}

            {errorMsg && (
              <div className="alert alert-danger" role="alert">
                {errorMsg}
              </div>
            )}

            <div className="card mb-4">
              <div className="card-body">
                <div className="row align-items-center mb-3">
                  <div className="col-lg-5">
                    <div className="form-control-group-icon search">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here..."
                        value={search}
                        onChange={this.handleSearchChange}
                      />
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="form-control-group-icon ms-3">
                        <select
                          className="form-select w-auto"
                          value={sort}
                          onChange={(e) =>
                            this.handleSortChange(e.target.value)
                          }
                        >
                          <option value="asc">Ascending</option>
                          <option value="desc">Descending</option>
                        </select>

                        <i className="fa-solid fa-magnifying-glass"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-body p-0">
                <table className="table align-middle mb-4">
                  <thead>
                    <tr>
                      <th>SN.</th>
                      <th>Name</th>
                      <th>Phone</th>
                      <th>Gender</th>
                      <th>Joining Date</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredAndSortedItems.length === 0 ? (
                      <tr>
                        <td colSpan="7" className="text-center">
                          No users available for the selected categories.
                        </td>
                      </tr>
                    ) : (
                      filteredAndSortedItems.map((category, index) => (
                        <tr key={index}>
                          <td>{index + 1}.</td>
                          <td>{category.name}</td>
                          <td>{category.phone}</td>
                          <td>{category.gender}</td>
                          <td>{formatDate(category.createdAt)}</td>

                          {permissions?.stylistManagement?.status && (
                            <td className="text-center">
                              {category.status && (
                                <div className="form-check form-switch d-inline-block p-0 m-0">
                                  <input
                                    className="form-check-input m-0"
                                    type="checkbox"
                                    role="switch"
                                    checked={category.status === "Active"}
                                    onChange={() =>
                                      this.handleToggleCheckBox(
                                        category._id,
                                        category.status
                                      )
                                    }
                                  />
                                </div>
                              )}
                            </td>
                          )}

                          {permissions?.stylistManagement?.edit && (
                            <td className="text-center">
                              <Link
                                to={`/EditExpert/${category._id}`}
                                title="Edit"
                                className="icon-btn"
                              >
                                <img
                                  src="assets/img/icon-edit.svg"
                                  alt=""
                                  title=""
                                />
                              </Link>
                            </td>
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>

                <div className="d-flex align-items-center justify-content-end p-3">
                  {currentPage > 1 && (
                    <button
                      className="pagination-prev me-2"
                      onClick={(e) => this.handlePageChange(currentPage - 1, e)}
                    >
                      <i className="fa-solid fa-angle-left"></i>
                    </button>
                  )}
                  <ul className="pagination m-0">
                    {Array.from({ length: totalPages }).map((_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={(e) => this.handlePageChange(index + 1, e)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}
                  </ul>
                  {currentPage < totalPages && (
                    <button
                      className="pagination-next"
                      onClick={(e) => this.handlePageChange(currentPage + 1, e)}
                    >
                      <i className="fa-solid fa-angle-right"></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {showPopup && (
          <div className="modal show d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Add New Expert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={this.togglePopup}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {successMsgModel && (
                    <div className="alert alert-success" role="alert">
                      {successMsgModel}
                    </div>
                  )}
                  {errorMsgModel && (
                    <div className="alert alert-danger" role="alert">
                      {errorMsgModel}
                    </div>
                  )}
                  <form>
                    <div className="form-group">
                      <label htmlFor="expertPhone">Phone</label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.expertPhone ? "is-invalid" : ""
                        }`}
                        id="expertPhone"
                        value={expertPhone}
                        onChange={this.handleChange}
                      />
                      {errors.expertPhone && (
                        <div className="invalid-feedback">
                          {errors.expertPhone}
                        </div>
                      )}
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleSubmit}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={this.togglePopup}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withPermissions(ExpertList);
