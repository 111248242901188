import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";

class AddBulkNotification extends Component {
  constructor(props) {
    super(props);

    const retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      title: "",
      message: "",
      selectedState: null,
      selectedDistrict: null,
      selectedCity: null,
      states: [],
      districts: [],
      cities: [],
      deliveryMethod: "",
      status: "Active",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: {},
      token: retrievedObject ? retrievedObject.token : "",
      loading: false,
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
    };
  }

  componentDidMount() {
    this.fetchStates();
  }

  fetchStates = async () => {
    const { token } = this.state;
    const { AdminStateList } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const stateUrl = `${AdminStateList}`;

    const statesResponse = await APICaller(
      stateUrl,
      method,
      "",
      contentType,
      token
    );

    const { states } = statesResponse.data;
    const statesData = states.map((state) => ({
      value: state._id,
      label: state.state_name,
    }));

    this.setState({ states: statesData });
  };

  fetchDistrictsByState = async (stateValue) => {
    const { token } = this.state;
    const { AdmingetDistrictByStateName } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const districtUrl = `${AdmingetDistrictByStateName}/${stateValue}`;

    const districtResponse = await APICaller(
      districtUrl,
      method,
      "",
      contentType,
      token
    );

    const { data, status } = districtResponse.data;

    if (status === 200) {
      const districtsData = data.map((district) => ({
        value: district._id,
        label: district.district_name,
      }));

      const selectedDistricts = districtsData || [];
      this.setState({ districts: selectedDistricts, cities: [] });
    } else {
      console.error("Failed to fetch districts");
    }
  };

  fetchCitiesByDistrict = async (districtValue) => {
    const { token } = this.state;
    const { AdmingetCityBYDistrictName } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const cityUrl = `${AdmingetCityBYDistrictName}/${districtValue}`;

    const cityResponse = await APICaller(
      cityUrl,
      method,
      "",
      contentType,
      token
    );

    const { data, status } = cityResponse.data;

    if (status === 200) {
      const citiesData = data.map((city) => ({
        value: city._id,
        label: city.city_name,
      }));

      const selectedCities = citiesData || [];
      this.setState({ cities: selectedCities });
    } else {
      console.error("Failed to fetch cities");
    }
  };

  handleStateChange = (selectedOption) => {
    if (!selectedOption) {
      this.setState({
        selectedState: null,
        selectedDistrict: "",
        selectedCity: "",
        districts: [],
        cities: [],
      });
      return;
    }

    const selectedState = selectedOption.value;
    const stateLabel = selectedOption.label;

    this.setState(
      {
        selectedState: { value: selectedState, label: stateLabel },
        selectedDistrict: "",
        selectedCity: "",
        districts: [],
        cities: [],
      },
      () => {
        if (selectedState) {
          this.fetchDistrictsByState(selectedState);
        }
      }
    );
  };

  handleDistrictChange = (selectedOption) => {
    if (!selectedOption) {
      this.setState({
        selectedDistrict: null,
        selectedCity: "",
        cities: [],
      });
      return;
    }

    const selectedDistrict = selectedOption.value;
    const districtLabel = selectedOption.label;

    this.setState(
      {
        selectedDistrict: { value: selectedDistrict, label: districtLabel },
        selectedCity: "",
        cities: [],
      },
      () => {
        if (selectedDistrict) {
          this.fetchCitiesByDistrict(selectedDistrict);
        }
      }
    );
  };

  handleCityChange = (selectedOption) => {
    if (!selectedOption) {
      this.setState({ selectedCity: null });
      return;
    }

    const selectedCity = selectedOption.value;
    const cityLabel = selectedOption.label;

    this.setState({ selectedCity: { value: selectedCity, label: cityLabel } });
  };

  validateForm = () => {
    const {
      title,
      message,
      selectedState,
      selectedDistrict,
      selectedCity,
      deliveryMethod,
    } = this.state;
    const newErrors = {};

    if (!title) {
      newErrors.title = "Title is required.";
    }

    if (!message) {
      newErrors.message = "Message is required.";
    }

    if (!selectedState) {
      newErrors.selectedState = "State is required.";
    }

    if (!selectedDistrict) {
      newErrors.selectedDistrict = "District is required.";
    }

    if (!selectedCity) {
      newErrors.selectedCity = "City is required.";
    }

    if (!deliveryMethod) {
      newErrors.deliveryMethod = "Delivery method is required.";
    }

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();
    if (Object.keys(newErrors).length > 0) {
      this.setState({ errors: newErrors });
    } else {
      this.setState({ loading: true, errors: {} });

      const {
        title,
        message,
        selectedState,
        selectedDistrict,
        selectedCity,
        deliveryMethod,
        status,
        token,
      } = this.state;

      const { AdminSendBulkNotifications } = adminendPoints; // Update with your endpoint
      const method = "POST";
      const body = {
        title,
        message,
        state: selectedState,
        district: selectedDistrict,
        city: selectedCity,
        deliveryMethod,
        status,
        adminId: "64d25bf44a62e3dbdddc6f74",
      };
      const contentType = "application/json";

      try {
        const response = await APICaller(
          AdminSendBulkNotifications,
          method,
          body,
          contentType,
          token
        );

        const { status } = response.data;

        if (status === 200) {
          // Display success message and hide the spinner
          this.setState({
            successMsg: "Bulk notification created successfully.",
            errorMsg: "",
            loading: false,
          });

          // Show success message for 3 seconds, then refresh the listing and close the modal
          setTimeout(() => {
            this.setState({ successMsg: "" });
            this.props.onAddSuccess();
            this.props.handleCloseAddContent(); // Close modal or form after successful submission
          }, 3000); // Wait for 3 seconds before closing and refreshing
        } else {
          this.setState({
            errorMsg: "Failed to create bulk notification. Please try again.",
            successMsg: "",
            loading: false,
          });
        }
      } catch (error) {
        console.error("Error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the notification. Please try again.",
          successMsg: "",
          loading: false,
        });
      }
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleEditorChange = (value) => {
    this.setState({ message: value });
  };

  render() {
    const {
      title,
      message,
      selectedState,
      selectedDistrict,
      selectedCity,
      deliveryMethod,
      status,
      errors,
      redirect,
      successMsg,
      errorMsg,
      predefinedState,
      predefinedDistrict,
      predefinedCity,
      states,
      districts,
      cities,
      loading,
    } = this.state;

    const { isVisibleAdd, handleCloseAddContent } = this.props;

    // if (redirect) {
    //   return <Navigate to="/BulkNotification" />;
    // }

    return (
      <div>
        <div
          className={`right-side-popup right-side-popup-w75 ${
            isVisibleAdd ? "" : "right-side-popup-hide"
          }`}
        >
          <div
            className="right-side-popup-close"
            onClick={handleCloseAddContent}
          >
            <i className="fa-solid fa-angles-right"></i>
          </div>
          <div className="right-side-popup-inner">
            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex align-items-center mb-4">
                  <h4 className="card-title mb-0">Add Bulk Notification</h4>
                </div>

                <form
                  className="form-area row align-items-center mb-5"
                  onSubmit={this.handleSubmit}
                >
                  {errorMsg && (
                    <div className="alert alert-danger">{errorMsg}</div>
                  )}
                  {successMsg && (
                    <div className="alert alert-success">{successMsg}</div>
                  )}

                  {loading && (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}

                  <div className="form-area1 row g-4">
                    <div className="col-lg-12">
                      <label htmlFor="title" className="form-label">
                        Title
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.title ? "is-invalid" : ""
                        }`}
                        id="title"
                        name="title"
                        value={title}
                        onChange={this.handleInputChange}
                        placeholder="Enter the title here..."
                      />
                      {errors.title && (
                        <div className="invalid-feedback">{errors.title}</div>
                      )}
                    </div>

                    <div className="col-lg-12">
                      <label htmlFor="message" className="form-label">
                        Message
                      </label>
                      <div
                        className={`quill-editor ${
                          errors.message ? "is-invalid" : ""
                        }`}
                      >
                        <ReactQuill
                          value={message}
                          onChange={this.handleEditorChange}
                          theme="snow"
                          placeholder="Write your message here..."
                        />
                      </div>
                      {errors.message && (
                        <p className="invalid-feedback">{errors.message}</p>
                      )}
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="state" className="form-label">
                          Select State:
                        </label>
                        <Select
                          id="state"
                          value={selectedState || predefinedState}
                          options={states}
                          onChange={this.handleStateChange}
                          className={errors.selectedState ? "is-invalid" : ""}
                        />
                        {errors.selectedState && (
                          <div className="invalid-feedback">
                            {errors.selectedState}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="district" className="form-label">
                          Select District:
                        </label>
                        <Select
                          id="district"
                          value={selectedDistrict || predefinedDistrict}
                          options={districts}
                          onChange={this.handleDistrictChange}
                          className={
                            errors.selectedDistrict ? "is-invalid" : ""
                          }
                        />
                        {errors.selectedDistrict && (
                          <div className="invalid-feedback">
                            {errors.selectedDistrict}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="city" className="form-label">
                          Select City:
                        </label>
                        <Select
                          id="city"
                          value={selectedCity || predefinedCity}
                          onChange={this.handleCityChange}
                          options={cities}
                          className={errors.selectedCity ? "is-invalid" : ""}
                        />
                        {errors.selectedCity && (
                          <div className="invalid-feedback">
                            {errors.selectedCity}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <label htmlFor="deliveryMethod" className="form-label">
                        Delivery Method
                      </label>
                      <select
                        className={`form-control ${
                          errors.deliveryMethod ? "is-invalid" : ""
                        }`}
                        id="deliveryMethod"
                        name="deliveryMethod"
                        value={deliveryMethod}
                        onChange={this.handleInputChange}
                      >
                        <option value="">Select Value</option>
                        <option value="email">Email</option>
                        <option value="sms">SMS</option>
                        <option value="push">Push Notification</option>
                      </select>
                      {errors.deliveryMethod && (
                        <div className="invalid-feedback">
                          {errors.deliveryMethod}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="">
                    <button type="submit" className="btn-blue">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`right-side-popup-backdrop ${
            isVisibleAdd ? "" : "d-none"
          }`}
        ></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});

export default connect(mapStateToProps)(AddBulkNotification);
