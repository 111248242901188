import React, { Component } from "react";
import APICaller from "../../utils/apicaller";
import { adminendPoints } from "../../config";
import { getLocalStorageItem } from "../../store/localStorage";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";

class EditAssignServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      userid: "",
      token: getLocalStorageItem("myStylistAdmin")?.token || "",
      price: "",
      service_id: "", // For preselecting service dropdown
      sub_service_id: "", // For preselecting sub-service dropdown
      best_service: "",
      selectedFile: null,
      services: [], // List of all services
      sub_services: [], // List of all sub-services for the selected service
      successMsg: "",
      errorMsg: "",
      errors: {},
      loading: false,
      redirect: false,
      time_taken: "", // Added time_taken
      serviceId: "", // Added time_taken
      user_id: "", // Added time_taken
    };
  }

  componentDidMount() {
    const urlPath = window.location.pathname.split("/");
    const id = urlPath[urlPath.length - 1];

    const params = new URLSearchParams(window.location.search);
    const userid = params.get("userid"); // Extract `userid` from the query parameters

    if (id && userid) {
      console.log("hihih");

      this.fetchService(userid);
      this.getUserAssignService(id, userid);
    } else {
      console.error("ID or UserID is missing from the URL");
    }
  }

  // Fetch assigned service details
  getUserAssignService = async (id, userid) => {
    const { token } = this.state;

    try {
      const response = await APICaller(
        adminendPoints.AdminGetUserAssignService,
        "POST",
        { userid, id },
        "application/json",
        token
      );

      if (response.data && response.data.message === "AssignService found") {
        const { data } = response.data;
        const {
          _id,
          user_id,
          service_id,
          sub_service_id,
          price,
          best_service,
          time_taken,
        } = data;

        this.setState(
          {
            service_id,
            sub_service_id,
            price,
            best_service,
            time_taken,
            serviceId: _id,
            id: _id,
            user_id: user_id,
            userid: user_id,
          },
          () => this.fetchSubServices(service_id)
        );
      } else {
        this.setErrorMsg("Failed to retrieve assigned service.");
      }
    } catch (error) {
      console.error("Error fetching assigned service:", error);
      this.setErrorMsg("An error occurred while fetching the service.");
    }
  };

  fetchService = async (id) => {
    const { token } = this.state;
    const { AdminServicesListMobile } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    const servicesResponse = await APICaller(
      AdminServicesListMobile,
      method,
      "",
      contentType,
      token
    );

    const { services, status } = servicesResponse.data;

    if (status === 200) {
      this.setState({ services, id });
    }
  };

  fetchSubServices = async (serviceId) => {
    const { token } = this.state;

    const body = { serviceIds: serviceId };

    try {
      const response = await APICaller(
        adminendPoints.AdminSubServicesListMobile,
        "POST",
        body,
        "application/json",
        token
      );
      const { status, subServices } = response.data;

      if (status === 200) {
        this.setState({ sub_services: subServices });
      } else {
        this.setErrorMsg("Failed to fetch sub services. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching sub services:", error);
      this.setErrorMsg("An error occurred. Please try again.");
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "hours" || name === "minutes") {
      this.setState((prevState) => ({
        time_taken: {
          ...prevState.time_taken,
          [name]: value,
        },
      }));
    } else {
      this.setState({ [name]: value });

      if (name === "service_id") {
        this.fetchSubServices(value);
      }
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();
    //this.setState({ loading: true });

    if (Object.keys(newErrors).length > 0) {
      this.setState(
        { errorMsg: "", successMsg: "", errors: newErrors, loading: false },
        () => {
          window.setTimeout(() => {
            this.setState({ errors: {} });
          }, 5000);
        }
      );
    } else {
      this.setState({ errors: {} });
      const {
        service_id,
        sub_service_id,
        best_service,
        price,
        selectedFile,
        token,
        time_taken,
        serviceId,
        user_id,
      } = this.state;

      const { AdminUpdateAssignServiceByUser } = adminendPoints;
      const method = "POST";

      const body = {
        id: serviceId,
        user_id: user_id,
        fileName: selectedFile,
        service_id,
        sub_service_id,
        best_service,
        price,
        time_taken,
      };

      const contentType = "application/json";

      try {
        const response = await APICaller(
          AdminUpdateAssignServiceByUser,
          method,
          body,
          contentType,
          token
        );

        if (response.status === 200) {
          this.setState(
            {
              successMsg: "Service assigned updated successfully.",
              errorMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
              }, 5000);
            }
          );
        } else {
          this.setErrorMsg("Failed to assign service. Please try again.");
        }
      } catch (error) {
        console.error("Error assigning service:", error);
        this.setErrorMsg(
          "An error occurred while assigning the service. Please try again."
        );
      }
    }
  };

  validateForm = () => {
    const { service_id, sub_service_id, best_service, price, time_taken } =
      this.state;
    const validationErrors = {};
    if (!service_id) validationErrors.service_id = "Service Name is required";
    if (!sub_service_id)
      validationErrors.sub_service_id = "Sub Service Name is required";
    if (!best_service)
      validationErrors.best_service = "Best Service is required";
    if (!price) validationErrors.price = "Price is required";
    if (!time_taken) validationErrors.time_taken = "Time Taken is required"; // Validation for time_taken
    return validationErrors;
  };

  setErrorMsg = (msg) => {
    this.setState({ errorMsg: msg }, () => {
      setTimeout(() => this.setState({ errorMsg: "" }), 3000);
    });
  };

  setSuccessMsg = (msg) => {
    this.setState({ successMsg: msg }, () => {
      setTimeout(() => this.setState({ successMsg: "" }), 5000);
    });
  };
  render() {
    const {
      token,
      errors,
      service_id,
      sub_service_id,
      services,
      sub_services,
      successMsg,
      errorMsg,
      best_service,
      price,
      redirect,
      id,
      userid,
      loading,
    } = this.state;

    if (redirect) {
      return <Navigate to={`/AssignServices/${userid}`} />;
    }

    if (!token) {
      return <Navigate to="/" />;
    }

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="main-right-content-inner">
            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex align-items-center mb-4">
                  <h4 className="card-title mb-0">Update Assign Service</h4>
                  <div className="ms-auto">
                    <Link
                      to={`/AssignServices/${userid}`}
                      className="btn-light1 ms-2"
                    >
                      <i className="fa-solid fa-arrow-left-long me-2"></i>
                      <span>Back</span>
                    </Link>
                  </div>
                </div>
                <form onSubmit={this.handleSubmit}>
                  {errorMsg && (
                    <div className="alert alert-danger">{errorMsg}</div>
                  )}
                  {successMsg && (
                    <div className="alert alert-success">{successMsg}</div>
                  )}

                  {loading && (
                    <div className="d-flex justify-content-center align-items-center loader">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                  <div className="form-area1 row g-4">
                    <div className="col-lg-6">
                      <label htmlFor="service_id" className="form-label">
                        Service Name
                      </label>
                      <select
                        className="form-select"
                        id="service_id"
                        name="service_id"
                        value={service_id}
                        onChange={this.handleInputChange}
                        disabled
                      >
                        <option value="">Select Service</option>
                        {services.map((service) => (
                          <option key={service._id} value={service._id}>
                            {service.service_name}
                          </option>
                        ))}
                      </select>
                      {errors.service_id && (
                        <p className="error">{errors.service_id}</p>
                      )}
                    </div>

                    <div className="col-lg-6">
                      <label htmlFor="sub_service_id" className="form-label">
                        Sub Service Name
                      </label>
                      <select
                        className="form-select"
                        id="sub_service_id"
                        name="sub_service_id"
                        value={sub_service_id}
                        onChange={this.handleInputChange}
                        disabled
                      >
                        <option value="">Select Sub Service</option>
                        {sub_services.map((subService) => (
                          <option key={subService._id} value={subService._id}>
                            {subService.sub_service_name}
                          </option>
                        ))}
                      </select>
                      {errors.sub_service_id && (
                        <p className="error">{errors.sub_service_id}</p>
                      )}
                    </div>

                    <div className="col-lg-6">
                      <label htmlFor="price" className="form-label">
                        Price
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="price"
                        placeholder="Price"
                        name="price"
                        value={price}
                        onChange={this.handleInputChange}
                        readOnly
                      />
                      {errors.price && <p className="error">{errors.price}</p>}
                    </div>

                    <div className="col-lg-6">
                      <label htmlFor="best_service" className="form-label">
                        Best Service
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="best_service"
                        placeholder="Best Service"
                        name="best_service"
                        value={best_service}
                        onChange={this.handleInputChange}
                        readOnly
                      />
                      {errors.best_service && (
                        <p className="error">{errors.best_service}</p>
                      )}
                    </div>

                    <div className="col-lg-6">
                      <label htmlFor="hours" className="form-label">
                        Time Taken (Hours)
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="hours"
                        name="hours"
                        placeholder="Hours"
                        value={this.state.time_taken.hours}
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="minutes" className="form-label">
                        Time Taken (Minutes)
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="minutes"
                        name="minutes"
                        placeholder="Minutes"
                        value={this.state.time_taken.minutes}
                        onChange={this.handleInputChange}
                      />
                      {errors.time_taken && (
                        <p className="error">{errors.time_taken}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-12">
                    <button type="submit" className="btn-blue">
                      Update Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditAssignServices;
