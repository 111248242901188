import React, { Component } from "react";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { formatDate } from "../../utils/commonFunction";
import { Link } from "react-router-dom";

class AddSubServices extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      serviceFor: "",
      frequently_used: "",
      id: "",
      searchTerm: "",
      selectedRows: [],
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      sub_service_name: "",
      fileName: "",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: {},
      results: "",
      token: retrievedObject ? retrievedObject.token : "",
      selectedFile: null,
      previewURL: null,
      sortOrder: "",
      selectedStatus: "All",
      description: "",
      status: "In-Active",
      loading: false,
      featured_image_url: "https://mystylist-media.s3.amazonaws.com",
    };
  }

  handleSearchChange = (e) => {
    this.setState({
      searchTerm: e.target.value,
    });
  };

  toggleCheckbox = (userId) => {
    const { selectedRows } = this.state;
    if (selectedRows.includes(userId)) {
      this.setState({
        selectedRows: selectedRows.filter((id) => id !== userId),
      });
    } else {
      this.setState({
        selectedRows: [...selectedRows, userId],
      });
    }
  };

  componentDidMount() {
    const pathParts = window.location.pathname.split("/");
    const serviceid = pathParts[pathParts.length - 2];
    const serviceFor = pathParts[pathParts.length - 1];

    this.getSubServiceResults(serviceid, serviceFor);
  }

  getSubServiceResults = (serviceid, serviceFor) => {
    const { token } = this.state;

    const { AdminAllSubService } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    const url = `${AdminAllSubService}/${serviceid}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, service } = response.data;

        if (status === 200) {
          this.setState({
            results: service.subServices,
            id: serviceid,
            serviceFor: serviceFor,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  validateForm = () => {
    const { sub_service_name, selectedFile } = this.state;
    const newErrors = {};

    if (!sub_service_name) {
      newErrors.sub_service_name = "Sub Service Name is required.";
    }

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();
    this.setState({ loading: true });
    if (Object.keys(newErrors).length > 0) {
      this.setState(
        { errorMsg: "", successMsg: "", errors: newErrors, loading: false },
        () => {
          window.setTimeout(() => {
            this.setState({ errors: {} });
          }, 5000);
        }
      );
    } else {
      this.setState({ errors: {} });
      const {
        serviceFor,
        frequently_used,
        sub_service_name,
        selectedFile,
        token,
        id,
        description,
        status,
      } = this.state;

      const { AdminAddSubService } = adminendPoints;
      const formData = new FormData();
      formData.append("sub_service_name", sub_service_name);
      formData.append("fileName", selectedFile);
      formData.append("serviceId", id);
      formData.append("description", description);
      formData.append("status", status);
      formData.append("frequently_used", frequently_used);
      formData.append("serviceFor", serviceFor);

      const method = "POST";
      const body = formData;

      try {
        const response = await APICaller(
          AdminAddSubService,
          method,
          body,
          {},
          token
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsg: "Sub Services created successfully.",
              errorMsg: "",
            },
            () => {
              this.setState({
                sub_service_name: "",
                selectedFile: null,
                previewURL: null,
                fileName: "",
                description: "",
                loading: false,
              });
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
                if (this.props.onCategoryInsert) {
                  this.props.onCategoryInsert();
                }
              }, 5000);
            }
          );
          this.getSubServiceResults(id);
          this.forceUpdate();
        } else if (status === 201) {
          this.setState(
            {
              errorMsg: "This Sub Services name is already exist",
              successMsg: "",
              loading: false,
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to create Sub Services. Please try again.",
              successMsg: "",
              loading: false,
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the category. Please try again.",
          successMsg: "",
          loading: false,
        });
      }
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleFileSelect = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        selectedFile: file,
        previewURL: reader.result,
        fileName: file.name, // Set the fileName state to the actual file name
      });
    };
    reader.readAsDataURL(file);
  };

  handleFileRemove = () => {
    document.getElementById("fileName").value = "";

    this.setState({
      selectedFile: null,
      previewURL: null,
      fileName: "",
    });
  };

  handleStatusChange = (e) => {
    this.setState({ status: e.target.value });
  };

  openImageModal = (imageUrl) => {
    this.setState({
      isImageModalOpen: true,
      selectedImageUrl: imageUrl,
    });
  };

  closeImageModal = () => {
    this.setState({
      isImageModalOpen: false,
      selectedImageUrl: "",
    });
  };

  handleToggleCheckBoxSub = async (subServiceId, currentStatus) => {
    const { token, id } = this.state;
    const { AdminUpdateStatusSubService } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    const newStatus = currentStatus === "Active" ? "In-Active" : "Active";

    const body = {
      subServiceId,
      status: newStatus,
    };

    try {
      const response = await APICaller(
        AdminUpdateStatusSubService,
        method,
        body,
        contentType,
        token
      );

      const { status, message } = response.data;

      if (status === 200) {
        this.setState((prevState) => ({
          results: prevState.results.map((subServices) =>
            subServices._id === subServiceId
              ? { ...subServices, status: newStatus }
              : subServices
          ),
          successMsg: `Sub Service Status is ${newStatus}`,
          errorMsg: "",
        }));
        setTimeout(() => {
          this.setState({ successMsg: "" });
        }, 2000);
        this.getSubServiceResults(id);
      } else {
        console.error("Failed to update faq status");
        this.setState({
          errorMsg: message,
          successMsg: "", // Clear any previous success messages
        });
      }
    } catch (error) {
      console.error("Error making data call:", error);
      this.setState({
        errorMsg: "An error occurred while updating offer status.",
        successMsg: "", // Clear any previous success messages
      });
    }
  };

  handlePageChange = (pageNumber, event) => {
    event.preventDefault();
    this.setState({ currentPage: pageNumber });
  };

  render() {
    const {
      sub_service_name,
      errors,
      successMsg,
      errorMsg,
      previewURL,
      results,
      currentPage,
      itemsPerPage,
      selectedRows,
      searchTerm,
      sortOrder,
      selectedStatus,
      description,
      status,
      loading,
      featured_image_url,
      frequently_used,
      serviceFor,
    } = this.state;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const userData = results;

    const filteredAndSortedItems = Array.isArray(userData)
      ? [...userData]
          .filter((user) => {
            const matchesSearch =
              user.sub_service_name
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              user.status.toLowerCase().includes(searchTerm.toLowerCase());

            const matchesStatus =
              selectedStatus === "All" || user.status === selectedStatus;

            return matchesSearch && matchesStatus;
          })
          .sort((a, b) => {
            // Sorting by user name
            return sortOrder === "asc"
              ? a.sub_service_name.localeCompare(b.sub_service_name)
              : b.sub_service_name.localeCompare(a.sub_service_name);
          })
      : [];

    const currentItems = filteredAndSortedItems.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    const totalPages = Math.ceil(filteredAndSortedItems.length / itemsPerPage);

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="main-right-content-inner">
            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex align-items-center mb-4">
                  <div className="bs-r-6 ps-3">
                    <h4 className="card-title mb-0">Add Services</h4>
                  </div>
                  <div className="ms-auto">
                    <Link to="/ManageServices" className="btn-light1 ms-2">
                      <i class="fa-solid fa-arrow-left-long me-2"></i>
                      <span>Back</span>
                    </Link>
                  </div>
                </div>

                <form
                  className="form-area row align-items-center mb-5"
                  onSubmit={this.handleSubmit}
                >
                  {errorMsg && (
                    <div className="alert alert-danger" role="alert">
                      {errorMsg}
                    </div>
                  )}

                  {successMsg && (
                    <div className="alert alert-success" role="alert">
                      {successMsg}
                    </div>
                  )}

                  {loading && (
                    <div className="d-flex justify-content-center align-items-center loader">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}

                  <div className="form-area1 row g-4">
                    <div className="col d-none">
                      <div className="upload-img-box">
                        <i className="fa-solid fa-file-arrow-up"></i>
                        <p className="mb-0">Upload Image</p>
                        <input
                          type="file"
                          className="form-control"
                          id="fileName"
                          placeholder="Absence through"
                          name="fileName"
                          accept="image/*"
                          onChange={this.handleFileSelect}
                        />
                        {previewURL && (
                          <div>
                            <img
                              src={previewURL}
                              alt="Category Preview"
                              style={{ width: "100px", height: "100px" }}
                            />
                            <button
                              type="button"
                              onClick={this.handleFileRemove}
                            >
                              Remove
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <label for="" className="form-label">
                        Services Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="sub_service_name"
                        placeholder="Services Name"
                        name="sub_service_name"
                        value={sub_service_name}
                        onChange={this.handleInputChange}
                      />
                      {errors.sub_service_name && (
                        <p className="error">{errors.sub_service_name}</p>
                      )}
                    </div>
                    <div className="col">
                      <label htmlFor="frequently_used" className="form-label">
                        Frequency (in days)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="frequently_used"
                        placeholder="Frequency (in days)"
                        name="frequently_used"
                        value={frequently_used}
                        onChange={this.handleInputChange}
                      />
                    </div>

                    <div className="col-lg-12">
                      <label htmlFor="description" className="form-label">
                        Service Description
                      </label>
                      <textarea
                        className="form-control"
                        id="description"
                        placeholder="Service Description"
                        name="description"
                        value={description}
                        onChange={this.handleInputChange}
                      ></textarea>
                    </div>

                    <div className="form-group d-none">
                      <label htmlFor="serviceFor">Service For:</label>
                      <select
                        id="serviceFor"
                        name="serviceFor"
                        className="form-control"
                        value={serviceFor}
                        onChange={this.handleInputChange}
                      >
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>

                    <div className="col-lg-12">
                      <button type="submit" className="btn-blue">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
                <div className="row align-items-center mb-3">
                  <div className="col-lg-5">
                    <h4 className="card-title mb-0">Services List</h4>
                  </div>
                  <div className="col-lg-7">
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="form-control-group-icon search">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search here..."
                          value={this.state.searchTerm}
                          onChange={this.handleSearchChange}
                        />
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </div>
                      <div className="form-control-group-icon ms-3">
                        <select
                          className="form-select w-auto"
                          value={sortOrder}
                          onChange={(e) =>
                            this.setState({ sortOrder: e.target.value })
                          }
                        >
                          <option value="asc">Ascending</option>
                          <option value="desc">Descending</option>
                        </select>
                        <i class="fa-solid fa-filter"></i>
                      </div>
                      <div className="form-control-group-icon ms-3">
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={selectedStatus}
                          onChange={(e) =>
                            this.setState({ selectedStatus: e.target.value })
                          }
                        >
                          <option value="All">ALL</option>
                          <option value="Active">Active</option>
                          <option value="In-Active">In-Active</option>
                        </select>
                        <i class="fa-solid fa-eye"></i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-body p-0">
                  <table className="table align-middle mb-0">
                    <thead>
                      <tr>
                        <th>SN.</th>
                        <th>Sort Order</th>
                        <th>Service For</th>
                        <th>Frequently Used</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems.length === 0 ? (
                        <tr>
                          <td colSpan="7" className="text-center">
                            No users available for the selected categories.
                          </td>
                        </tr>
                      ) : (
                        currentItems.map((user, index) => (
                          <tr key={user._id}>
                            <td>
                              {(currentPage - 1) * itemsPerPage + (index + 1)}
                            </td>
                            <td>{user.sub_service_name}</td>
                            <td>{user.service_for}</td>
                            <td>{user.frequently_used}</td>
                            <td>
                              {user.status && (
                                <div className="form-check form-switch d-inline-block p-0 m-0">
                                  <input
                                    className="form-check-input m-0"
                                    type="checkbox"
                                    role="switch"
                                    checked={user.status === "Active"}
                                    onChange={() =>
                                      this.handleToggleCheckBoxSub(
                                        user._id,
                                        user.status
                                      )
                                    }
                                  />
                                </div>
                              )}
                            </td>
                            <td className="text-center">
                              <Link
                                to={`/editSubServices/${user._id}`}
                                className="icon-btn"
                              >
                                <img
                                  src={`${process.env.REACT_APP_BASE_URL}/assets/img/icon-edit.svg`}
                                  alt="View Service"
                                  title="View Service"
                                />
                              </Link>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>

                    {this.state.isImageModalOpen && (
                      <div
                        className="modal"
                        tabIndex="-1"
                        role="dialog"
                        style={{ display: "block" }}
                      >
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-body">
                              <span
                                className="close"
                                onClick={this.closeImageModal}
                              >
                                &times;
                              </span>
                              <img
                                src={this.state.selectedImageUrl}
                                alt="View Image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </table>
                  <div className="d-flex align-items-center justify-content-end p-3">
                    {currentPage > 1 && (
                      <button
                        className="pagination-prev me-2"
                        onClick={(e) =>
                          this.handlePageChange(currentPage - 1, e)
                        }
                      >
                        <i className="fa-solid fa-angle-left"></i>
                      </button>
                    )}

                    <ul className="pagination m-0">
                      {Array.from({ length: totalPages }).map((_, index) => (
                        <li
                          key={index}
                          className={`page-item ${
                            currentPage === index + 1 ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={(e) => this.handlePageChange(index + 1, e)}
                          >
                            {index + 1}
                          </button>
                        </li>
                      ))}
                    </ul>

                    {currentPage < totalPages && (
                      <button
                        className="pagination-next"
                        onClick={(e) =>
                          this.handlePageChange(currentPage + 1, e)
                        }
                      >
                        <i className="fa-solid fa-angle-right"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddSubServices;
