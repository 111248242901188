import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import moment from "moment";
import Select from "react-select";

class updateLocation extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    this.state = {
      token: retrievedObject ? retrievedObject.token : "",
      selectedState: null,
      selectedDistrict: null,
      selectedCity: null,
      states: [],
      districts: [],
      cities: [],
      reviewsResults: [],
      predefinedState: null,
      predefinedDistrict: null,
      predefinedCity: null,
      username: "",
      password: "",
      address: "",
      pincode: "",
      latitude: "",
      longitude: "",
      experience: "",
      gender: "",
      fullName: "",
      email: "",
      homeVisit: "",
      mobileNumber: "",
      freelancer: "",
      salon_name: "",
      user_information: [],
      _id: "",
      successMsg: "",
      errorMsg: "",
      errors: {},
      redirect: false,
      loading: false,
      errors: {},
    };
  }

  componentDidMount() {
    const userid = window.location.pathname.split("/").pop();
    this.fetchUserData(userid);
    this.fetchCity();
  }

  fetchUserData = async (userid) => {
    this.setState({ loading: true });
    const { token } = this.state;
    const { AdminExpertUserDetails } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      const body = { token, userid: userid };
      const response = await APICaller(
        AdminExpertUserDetails,
        method,
        body,
        contentType,
        token
      );

      const { status, user } = response.data;

      if (status === 200 && user) {
        const userInformation =
          user.user_information && user.user_information[0]
            ? user.user_information[0]
            : {};

        this.setState({
          loading: false,
          _id: userid,
          username: user.username || "",
          gender: user.gender || "",
          salon_name: user.salon_name || "",
          fullName: user.name || "",
          email: user.email || "",
          mobileNumber: user.phone || "",
          homeVisit: user.homeVisit || false,
          freelancer: user.freelancer || false,
          experience: userInformation.experience || "",
          user_information: [
            {
              aboutMe: userInformation.aboutMe || "",
              experience: userInformation.experience || "",
              course: userInformation.course || "",
              course_name: userInformation.course_name || "",
              facebook_url: userInformation.facebook_url || "",
              instagram_url: userInformation.instagram_url || "",
            },
          ],
          selectedState:
            user.state && user.state[0]
              ? {
                  value: user.state[0].state_id,
                  label: user.state[0].state_name,
                }
              : null,
          selectedDistrict:
            user.district && user.district[0]
              ? {
                  value: user.district[0].district_id,
                  label: user.district[0].district_name,
                }
              : null,
          selectedCity:
            user.city && user.city[0]
              ? {
                  value: user.city[0].city_id,
                  label: user.city[0].city_name,
                }
              : null,
          address:
            user.addresses && user.addresses[0]
              ? user.addresses[0].address.sector
              : "",
          pincode:
            user.addresses && user.addresses[0]
              ? user.addresses[0].address.pinCode
              : "",
          latitude:
            user.addresses &&
            user.addresses[0] &&
            user.addresses[0].address.location
              ? user.addresses[0].address.location.coordinates[0]
              : "",
          longitude:
            user.addresses &&
            user.addresses[0] &&
            user.addresses[0].address.location
              ? user.addresses[0].address.location.coordinates[1]
              : "",
        });
      } else if (status === 200 && !user) {
        // Handle the case when result is empty
        this.setState({
          loading: false,
          errorMsg: "No user data found",
          username: "",
          gender: "",
          salon_name: "",
          fullName: "",
          email: "",
          mobileNumber: "",
          homeVisit: false,
          freelancer: false,
          experience: "",
          user_information: [
            {
              aboutMe: "",
              experience: "",
              course: "",
              course_name: "",
              facebook_url: "",
              instagram_url: "",
            },
          ],
          selectedState: null,
          selectedDistrict: null,
          selectedCity: null,
          address: "",
          pincode: "",
          latitude: "",
          longitude: "",
        });
      } else {
        console.error("Failed to fetch user data");
        this.setState({
          errorMsg: "Failed to fetch user data",
          loading: false,
        });
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      this.setState({ errorMsg: "Failed to fetch user data", loading: false });
    }
  };

  fetchCity = async () => {
    const { token } = this.state;
    const { AdminCityListNew } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      const body = { token };
      const response = await APICaller(
        AdminCityListNew,
        method,
        JSON.stringify(body),
        contentType,
        token
      );

      const { data, status } = response.data;

      if (status === 200) {
        const citiesData = data.map((city) => ({
          value: city.city_id,
          label: city.city_name,
        }));

        const selectedCities = citiesData || [];
        this.setState({ cities: selectedCities });
      } else {
        console.error("Failed to fetch cities");
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    const selectedCity = this.state.cities.find(
      (city) => city.value === selectedCityId
    );

    if (!selectedCityId || !selectedCity) {
      this.setState({ selectedCity: null });
      return;
    }

    this.setState({
      selectedCity: {
        value: selectedCity.value,
        label: selectedCity.label,
      },
    });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSuccClose = (e) => {
    this.setState({
      successMsg: "",
    });
  };

  handleErrorClose = (e) => {
    this.setState({
      errorMsg: "",
    });
  };

  validateForm = () => {
    const {
      gender,
      freelancer,
      fullName,
      experience,
      address,
      homeVisit,
      selectedCity,
      pincode,
      salon_name,
      username,
      password,
      email,
      latitude,
      longitude,
    } = this.state;

    const errors = {};

    // Validation for fields that are always required
    if (!latitude) errors.latitude = "Latitude is required.";
    if (!longitude) errors.longitude = "Longitude is required.";
    if (!username) errors.username = "Username is required.";
    if (!password) errors.password = "Password is required.";
    if (!email) errors.email = "Email is required.";
    if (!fullName) errors.fullName = "Name is required.";
    if (!salon_name) errors.salon_name = "Salon Name is required.";
    if (!gender) errors.gender = "Gender is required.";
    if (!freelancer) errors.freelancer = "Freelancer status is required.";
    if (!experience) errors.experience = "Experience is required.";
    if (!homeVisit) errors.homeVisit = "Home visit preference is required.";
    if (!selectedCity) errors.selectedCity = "City is required.";
    if (!pincode) {
      errors.pincode = "Pincode is required.";
    } else if (!/^\d{6}$/.test(pincode)) {
      errors.pincode = "Pincode must be a 6-digit number.";
    }

    // Conditional validation for address when freelancer is "No"
    if (freelancer === "No" && !address) {
      errors.address = "Address is required.";
    }

    console.log(errors, "errors");

    // Update the state with error messages
    this.setState({ errors });

    // Return false if there are any errors
    return Object.keys(errors).length === 0;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const {
      _id,
      salon_name,
      freelancer,
      address,
      selectedCity,
      pincode,
      latitude,
      longitude,
      fullName,
      email,
      homeVisit,
      experience,
      gender,
      user_information,
      username,
      password,
    } = this.state;

    if (!this.validateForm()) {
      return;
    }

    const sector = address.trim() === "" ? "Chandigarh" : address;

    const body = {
      id: _id,
      salon_name: salon_name,
      gender: gender,
      name: fullName,
      email: email,
      username: username,
      password: password,
      homeVisit: homeVisit,
      experience: experience,
      freelancer: freelancer,
      state: {
        value: "672b2aaa1f2bfcb5c771f0c0",
        label: "Punjab",
      },
      district: {
        value: "66efe3808b132f5f798b5863",
        label: "Chandigarh",
      },
      city: {
        value: selectedCity.value,
        label: selectedCity.label,
      },
      addresses: [
        {
          address: {
            addressType: "Home",
            houseNumber: "",
            sector: sector,
            pinCode: pincode,
            landmark: "",
            location: {
              type: "Point",
              coordinates: [parseFloat(longitude), parseFloat(latitude)],
            },
          },
          isDefault: false,
        },
      ],

      user_information: [
        {
          aboutMe: user_information[0].aboutMe || "",
          experience: experience || user_information[0].experience,
          course: user_information[0].course || "No",
          course_name: user_information[0].course_name || "",
          facebook_url: user_information[0].facebook_url || "",
          instagram_url: user_information[0].instagram_url || "",
        },
      ],
    };

    const { OnboardingUpdateBussformation } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    this.setState({ loading: true });

    try {
      const response = await APICaller(
        OnboardingUpdateBussformation,
        method,
        body,
        contentType
      );

      const { status, data } = response;

      if (data && status === 200) {
        this.setState(
          {
            successMsg: "Location updated successfully.",
            errorMsg: "",
          },
          () => {
            setTimeout(() => {
              this.setState({ successMsg: "", redirect: true, loading: false });
            }, 5000);
          }
        );
      } else {
        this.setState(
          {
            errorMsg: "Failed to update data. Please try again.",
            successMsg: "",
            loading: false, // Set loading to false
          },
          () => {
            setTimeout(() => {
              this.setState({ errorMsg: "" });
            }, 3000);
          }
        );
      }
    } catch (error) {
      this.setState({
        errorMsg: "Error updating data. Please try again.",
        successMsg: "",
        loading: false, // Set loading to false
      });
    }
  };

  render() {
    const {
      cities,
      selectedCity,
      successMsg,
      errorMsg,
      redirect,
      errors,
      pincode,
      address,
      loading,
      experience,
      fullName,
      email,
      password,
      username,
      homeVisit,
      freelancer,
      gender,
      token,
      salon_name,
      predefinedCity,
      _id,
      latitude,
      longitude,
    } = this.state;

    if (redirect) {
      return <Navigate to={`/EditExpert/${_id}`} />;
    }

    const options = Array.from({ length: 30 }, (_, index) => index + 1);

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />

          <div className="card mb-4">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <div className="bs-r-6 ps-3">
                  <h4 className="card-title mb-0">Update Profile</h4>
                </div>
                <div className="ms-auto">
                  <Link to={`/EditExpert/${_id}`} className="btn-light1 ms-2">
                    <span>Back</span>
                  </Link>
                </div>
              </div>
              <form className="form-area1 row g-4" onSubmit={this.handleSubmit}>
                {errorMsg && (
                  <div className="alert alert-danger" role="alert">
                    {errorMsg}
                  </div>
                )}
                {successMsg && (
                  <div className="alert alert-success" role="alert">
                    {successMsg}
                  </div>
                )}
                {loading && (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Stylist Name</label>
                    <input
                      type="text"
                      name="fullName"
                      value={fullName}
                      onChange={this.handleInputChange}
                      className={`form-control ${
                        errors.fullName ? "is-invalid" : ""
                      }`}
                      placeholder="Enter here..."
                    />
                    {errors.fullName && (
                      <div className="invalid-feedback">{errors.fullName}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Email</label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.email ? "is-invalid" : ""
                      }`}
                      id="email"
                      placeholder="Email"
                      name="email"
                      value={email}
                      onChange={this.handleInputChange}
                    />
                    {errors.email && (
                      <div className="invalid-feedback">{errors.email}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Home Visit</label>
                    <select
                      name="homeVisit"
                      value={homeVisit}
                      onChange={this.handleInputChange}
                      className={`form-control ${
                        errors.homeVisit ? "is-invalid" : ""
                      }`}
                    >
                      <option value="">Please select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                    {errors.homeVisit && (
                      <div className="invalid-feedback">{errors.homeVisit}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Are you a Freelancer*</label>
                    <select
                      name="freelancer"
                      value={freelancer}
                      onChange={this.handleInputChange}
                      className={`form-control ${
                        errors.freelancer ? "is-invalid" : ""
                      }`}
                    >
                      <option value="">Please select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                    {errors.freelancer && (
                      <div className="invalid-feedback">
                        {errors.freelancer}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Salon Name*</label>
                    <input
                      type="text"
                      name="salon_name"
                      value={salon_name}
                      onChange={this.handleInputChange}
                      className={`form-control ${
                        errors.salon_name ? "is-invalid" : ""
                      }`}
                      placeholder="Enter here..."
                    />
                    {errors.salon_name && (
                      <div className="invalid-feedback">
                        {errors.salon_name}
                      </div>
                    )}
                  </div>
                </div>
                {freelancer === "No" && (
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Salon Address</label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.address ? "is-invalid" : ""
                        }`}
                        value={address}
                        name="address"
                        placeholder="Address here..."
                        onChange={this.handleInputChange}
                      />
                      {errors.address && (
                        <div className="text-danger">{errors.address}</div>
                      )}
                    </div>
                  </div>
                )}
                <div className="col-lg-6">
                  <div className="form-group">
                    <label for="" className="form-label">
                      Username
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.username ? "is-invalid" : ""
                      }`}
                      id="username"
                      placeholder="Username"
                      name="username"
                      value={username}
                      onChange={this.handleInputChange}
                    />
                    {errors.username && (
                      <div className="invalid-feedback">{errors.username}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label for="" className="form-label">
                      Password
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.password ? "is-invalid" : ""
                      }`}
                      id="password"
                      placeholder="Password"
                      name="password"
                      onChange={this.handleInputChange}
                    />
                    {errors.password && (
                      <div className="invalid-feedback">{errors.password}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="gender" className="form-label">
                      Gender
                    </label>
                    <select
                      className={`form-control ${
                        errors.gender ? "is-invalid" : ""
                      }`}
                      id="gender"
                      name="gender"
                      value={gender}
                      onChange={this.handleInputChange}
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                    {errors.gender && (
                      <div className="invalid-feedback">{errors.gender}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label for="" className="form-label">
                      Experience
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.experience ? "is-invalid" : ""
                      }`}
                      id="experience"
                      placeholder="experience"
                      name="experience"
                      value={experience}
                      onChange={this.handleInputChange}
                    />
                    {errors.experience && (
                      <div className="invalid-feedback">
                        {errors.experience}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label for="" className="form-label">
                      Pincode:
                    </label>
                    <input
                      type="text"
                      value={pincode}
                      className={`form-control ${
                        errors.pincode ? "is-invalid" : ""
                      }`}
                      name="pincode"
                      placeholder="Enter here"
                      onChange={this.handleInputChange}
                    />
                    {errors.pincode && (
                      <div className="invalid-feedback">{errors.pincode}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label for="" className="form-label">
                      Latitude:
                    </label>
                    <input
                      type="text"
                      value={latitude}
                      className={`form-control ${
                        errors.pincode ? "is-invalid" : ""
                      }`}
                      name="latitude"
                      placeholder="Enter here"
                      onChange={this.handleInputChange}
                    />
                    {errors.latitude && (
                      <div className="invalid-feedback">{errors.latitude}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label for="" className="form-label">
                      Longitude:
                    </label>
                    <input
                      type="text"
                      value={longitude}
                      className={`form-control ${
                        errors.longitude ? "is-invalid" : ""
                      }`}
                      name="longitude"
                      placeholder="Enter here"
                      onChange={this.handleInputChange}
                    />
                    {errors.longitude && (
                      <div className="invalid-feedback">{errors.longitude}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label
                      htmlFor="city"
                      className={`form-label ${
                        errors.selectedCity ? "is-invalid" : ""
                      }`}
                    >
                      Select City:
                    </label>
                    <select
                      id="city"
                      value={selectedCity ? selectedCity.value : ""} // Use selectedCity.id if selectedCity is defined
                      onChange={this.handleCityChange}
                      className="form-control"
                    >
                      <option value="">Select City</option>
                      {cities.map((city) => (
                        <option key={city.value} value={city.value}>
                          {city.label}
                        </option>
                      ))}
                    </select>

                    {errors.selectedCity && (
                      <div className="invalid-feedback">
                        {errors.selectedCity}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <button type="submit" className="btn-blue1">
                    Update Profile
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default updateLocation;
