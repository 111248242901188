import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";

class EditSubService extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    this.state = {
      loading: false,
      serviceFor: "",
      sub_service_name: "",
      status: "",
      fileName: "",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: {},
      token: retrievedObject ? retrievedObject.token : "",
      selectedFile: null,
      previewURL: null,
      results: "",
      id: "",
      description: "",
      frequently_used: "",
      sort_order: "",
    };
  }

  componentDidMount() {
    const svcid = window.location.pathname.split("/").pop();
    this.getSubServiceDetails(svcid);
  }

  getSubServiceDetails = (id) => {
    const { token } = this.state;
    const { AdminSubServicesDetails } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const url = `${AdminSubServicesDetails}/${id}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, data } = response.data;

        if (status === 200) {
          this.setState({
            results: data,
            sub_service_name: data.sub_service_name,
            id: data._id,
            status: data.status,
            serviceFor: data.service_for,
            description: data.description,
            frequently_used: data.frequently_used,
            sort_order: data.sort_order,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  validateForm = () => {
    const { sub_service_name, status, frequently_used } = this.state;
    const newErrors = {};

    if (!sub_service_name) {
      newErrors.sub_service_name = "Service Name is required.";
    }

    if (!status) {
      newErrors.status = "status is required.";
    }

    if (!frequently_used) {
      newErrors.frequently_used = "Frequently Used is required.";
    }

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        window.setTimeout(() => {
          this.setState({ errors: {} });
        }, 5000);
      });
    } else {
      this.setState({ errors: {} });
      const {
        sub_service_name,
        selectedFile,
        token,
        id,
        status,
        description,
        serviceFor,
        frequently_used,
        sort_order,
      } = this.state;

      const { AdminEditSubService } = adminendPoints;
      const formData = new FormData();
      formData.append("subServiceId", id);
      formData.append("serviceId", id);
      formData.append("sub_service_name", sub_service_name);
      formData.append("description", description);
      formData.append("status", status);
      formData.append("fileName", selectedFile);
      formData.append("serviceFor", serviceFor);
      formData.append("frequently_used", frequently_used);
      formData.append("sort_order", sort_order);

      const method = "POST";
      const body = formData;

      try {
        const response = await APICaller(
          AdminEditSubService,
          method,
          body,
          {},
          token
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsg: "Service Name updated successfully.",
              errorMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
              }, 5000);
            }
          );
        } else if (status === 201) {
          this.setState(
            {
              errorMsg: "This Service Name is already exist",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to create Service Name. Please try again.",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the Service Name. Please try again.",
          successMsg: "",
        });
      }
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleFileSelect = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        selectedFile: file,
        previewURL: reader.result,
        fileName: file.name,
      });
    };
    reader.readAsDataURL(file);
  };

  handleFileRemove = () => {
    document.getElementById("fileName").value = "";

    this.setState({
      selectedFile: null,
      previewURL: null,
      fileName: "", // Clear the fileName state
    });
  };

  handleStatusChange = (e) => {
    this.setState({ status: e.target.value });
  };

  render() {
    const {
      sub_service_name,
      errors,
      redirect,
      successMsg,
      errorMsg,
      token,
      previewURL,
      status,
      loading,
      serviceFor,
      description,
      frequently_used,
    } = this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    if (redirect) {
      return <Navigate to="/ManageServices" />;
    }

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />

          <div className="card mb-4">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <div className="bs-r-6 ps-3">
                  <h4 className="card-title mb-0">Edit Service Information</h4>
                  <p className="mb-0 tc-5">Edit Service</p>
                </div>
                <div className="ms-auto">
                  <Link to="/ManageServices" className="btn-light1 ms-2">
                    <i class="fa-solid fa-arrow-left-long me-2"></i>
                    <span>Back</span>
                  </Link>
                </div>
              </div>

              <form
                className="form-area row align-items-center mb-5"
                onSubmit={(e) => this.handleSubmit(e)}
              >
                {errorMsg && (
                  <div className="alert alert-danger" role="alert">
                    {errorMsg}
                  </div>
                )}

                {successMsg && (
                  <div className="alert alert-success" role="alert">
                    {successMsg}
                  </div>
                )}

                {loading && (
                  <div className="d-flex justify-content-center align-items-center loader">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}

                <div className="form-area1 row g-4">
                  <div className="col d-none">
                    <div className="upload-img-box">
                      <i className="fa-solid fa-file-arrow-up"></i>
                      <p className="mb-0">Upload Image</p>
                      <input
                        type="file"
                        className="form-control"
                        id="fileName"
                        placeholder="Absence through"
                        name="fileName"
                        accept="image/*"
                        onChange={this.handleFileSelect}
                      />
                      {previewURL && (
                        <div>
                          <img
                            src={previewURL}
                            alt="Category Preview"
                            style={{ width: "100px", height: "100px" }}
                          />
                          <button type="button" onClick={this.handleFileRemove}>
                            Remove
                          </button>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <label htmlFor="sub_service_name" className="form-label">
                      Service Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="sub_service_name"
                      placeholder="Service Name"
                      name="sub_service_name"
                      value={sub_service_name}
                      onChange={this.handleInputChange}
                    />
                    {errors && (
                      <div className="callout callout-danger">
                        {errors.sub_service_name && (
                          <p className="error">{errors.sub_service_name}</p>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="col d-none">
                    <label htmlFor="status" className="form-label">
                      Status
                    </label>
                    <select
                      className="form-select"
                      id="status"
                      aria-label="Default select example"
                      value={status}
                      onChange={this.handleStatusChange}
                    >
                      <option value="Active">Active</option>
                      <option value="In-Active">In-Active</option>
                    </select>
                  </div>

                  <div className="col-lg-6">
                    <label htmlFor="serviceFor">Service For:</label>
                    <select
                      id="serviceFor"
                      name="serviceFor"
                      className="form-control"
                      value={serviceFor}
                      onChange={this.handleInputChange}
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Both">Both</option>
                    </select>
                  </div>

                  <div className="col-lg-6">
                    <label htmlFor="frequently_used" className="form-label">
                      Frequently Used
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="frequently_used"
                      placeholder="Frequently Used"
                      name="frequently_used"
                      value={frequently_used}
                      onChange={this.handleInputChange}
                    />
                  </div>

                  <div className="col-lg-12">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <textarea
                      className="form-control"
                      id="description"
                      placeholder="Service Description"
                      name="description"
                      value={description}
                      onChange={this.handleInputChange}
                    ></textarea>
                  </div>

                  <div className="col-lg-12">
                    <button type="submit" className="btn-blue">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});
export default connect(mapStatetoProps)(EditSubService);
