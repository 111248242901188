import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import Header from "../Header/index";
import SideBar from "../SideBar/index";

class ExpertGallary extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      id: "",
      selectedImages: [],
      successMsg: "",
      errorMsg: "",
      token: retrievedObject ? retrievedObject.token : "",
      user_work_images: [],
      user_profile_images: [],
      loading: false,
      draggingIndex: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    const userid = window.location.pathname.split("/").pop();
    this.getUserResults(userid);
  }

  getUserResults = async (userid) => {
    try {
      const { token } = this.state;
      const { AdminExpertUserDetails } = adminendPoints;
      const expertUrl = `${AdminExpertUserDetails}`;
      const contentType = "application/json";
      const body = {
        userid,
      };

      const response = await APICaller(
        expertUrl,
        "POST",
        body,
        contentType,
        token
      );
      const { status, user } = response.data;

      if (user) {
        const userProfileImages = user.user_profile_images;

        this.setState({
          id: userid,
          user_profile_images: userProfileImages,
        });
      } else {
        this.setState({
          errorMsg: "Failed to create City. Please try again.",
          successMsg: "",
        });
      }
    } catch (error) {
      console.log("error making data call --> ", error);
    }
  };

  handleImageChange = (e) => {
    const selectedImagesArray = Array.from(e.target.files);
    const selectedImagesWithFeatured = selectedImagesArray.map(
      (image, index) => ({
        file: image,
        is_featured: index === 0 ? "1" : "0",
      })
    );

    this.setState({ selectedImages: selectedImagesWithFeatured });
  };

  handleFeaturedChange = (index, type) => {
    if (type === "image") {
      const updatedImages = this.state.selectedImages.map((image, i) => ({
        ...image,
        is_featured: i === index ? "1" : "0",
      }));
      this.setState({ selectedImages: updatedImages });
    }
  };

  validateForm = () => {
    const { selectedImages } = this.state;

    const newErrors = {};

    if (selectedImages.length === 0) {
      newErrors.user_profile_images = "Profile Image is required.";
    }

    return newErrors;
  };

  handleOpenDeleteModal = (itemId) => {
    this.setState({ showDeleteModal: true, deleteItemId: itemId });
  };

  handleCloseDeleteModal = () => {
    this.setState({ showDeleteModal: false, deleteItemId: null });
  };

  handleConfirmDelete = async () => {
    const { deleteItemId } = this.state;
    this.handleCloseDeleteModal();
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        window.setTimeout(() => {
          this.setState({ errors: {} });
        }, 5000);
      });
    } else {
      this.setState({ errors: {} });

      const { selectedImages, token, id } = this.state;

      const { AdminUploadExpertGallery } = adminendPoints;
      const formData = new FormData();
      formData.append("id", id);

      for (let i = 0; i < selectedImages.length; i++) {
        formData.append("user_profile_images", selectedImages[i].file);
        formData.append(
          "user_profile_images_featured",
          selectedImages[i].is_featured
        );
      }

      const method = "POST";
      const body = formData;
      this.setState({ loading: true });
      try {
        const response = await APICaller(
          AdminUploadExpertGallery,
          method,
          body,
          {},
          token
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsg: "Profile Images Deleted successfully.",
              errorMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "", loading: false });
                this.getUserResults(id);
              }, 5000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to update images. Please try again.",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({
                  errorMsg: "",
                  redirect: false,
                  loading: false,
                });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the category. Please try again.",
          successMsg: "",
          loading: false,
        });
      }
    }
  };

  handleDeleteImage = (imageId) => {
    if (window.confirm("Are you sure you want to delete this image?")) {
      this.handleDeleteImageAPI(imageId);
    }
  };

  handleDeleteImageAPI = async (imageId) => {
    this.setState({ loading: true });
    const { token, id } = this.state;

    const { AdminDeleteUserProfileImage } = adminendPoints;
    const method = "POST";
    const body = {
      id: imageId,
      userid: id,
    };
    const contentType = "application/json";

    try {
      const response = await APICaller(
        AdminDeleteUserProfileImage,
        method,
        body,
        contentType,
        token
      );
      if (response.status === 200) {
        this.setState(
          {
            successMsg: "Profile Images Deleted successfully.",
            errorMsg: "",
          },
          () => {
            setTimeout(() => {
              this.setState({ successMsg: "", loading: false });
              this.getUserResults(id);
            }, 5000);
          }
        );
      } else {
        this.setState({
          loading: false,
        });
      }
    } catch (error) {
      console.log("Error making API call:", error);
    }
  };

  handleDragStart = (e, id, index) => {
    this.setState({ draggingIndex: index });
    e.dataTransfer.setData("imageId", id); // Store the image ID
    e.target.classList.add("dragging"); // Add visual cue
  };

  handleDragEnd = (e) => {
    e.target.classList.remove("dragging"); // Remove visual cue
    this.setState({ draggingIndex: null });
  };

  handleDrop = async (e, newPosition) => {
    e.preventDefault();
    const draggedId = e.dataTransfer.getData("imageId");

    const draggedImage = this.state.user_profile_images.find(
      (img) => img._id === draggedId
    );

    if (!draggedImage) return;
    const updatedList = this.state.user_profile_images.filter(
      (img) => img._id !== draggedId
    );

    updatedList.splice(newPosition, 0, draggedImage);
    updatedList.forEach((image, index) => {
      image.sort_order = index;
    });

    this.setState({ user_profile_images: updatedList });

    try {
      await this.updateImageOrder(updatedList);
    } catch (error) {
      console.error("Error updating image order:", error);
    }
  };

  handleDragOver = (e) => {
    e.preventDefault();
  };

  updateImageOrder = async (updatedList) => {
    try {
      const { token, id } = this.state;
      const { AdminUpdateProfileSortOrder } = adminendPoints;
      const expertUrl = `${AdminUpdateProfileSortOrder}`;
      const contentType = "application/json";
      const body = {
        id,
        updatedList,
      };

      const response = await APICaller(
        expertUrl,
        "POST",
        body,
        contentType,
        token
      );

      if (response.status === 200) {
        this.setState(
          {
            successMsg: "Profile images order updated successfully.",
            errorMsg: "",
          },
          () => {
            setTimeout(() => {
              this.setState({ successMsg: "", loading: false });
              this.getUserResults(id);
            }, 5000);
          }
        );
      } else {
        this.setState({
          loading: false,
        });
      }
    } catch (error) {
      console.log("error making data call --> ", error);
    }
  };

  render() {
    const {
      id,
      selectedImages,
      redirect,
      successMsg,
      errorMsg,
      token,
      user_profile_images,
      loading,
    } = this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    if (redirect) {
      return <Navigate to="/ManageExperts" />;
    }

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />

          <div className="card mb-4">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <div className="bs-r-6 ps-3">
                  <h4 className="card-title mb-0">Update Profile</h4>
                </div>
                <div className="ms-auto">
                  <Link to={`/EditExpert/${id}`} className="btn-light1 ms-2">
                    <span>Back</span>
                  </Link>
                </div>
              </div>
              <form onSubmit={this.handleSubmit}>
                {errorMsg && (
                  <div className="alert alert-danger" role="alert">
                    {errorMsg}
                  </div>
                )}
                {successMsg && (
                  <div className="alert alert-success" role="alert">
                    {successMsg}
                  </div>
                )}

                {loading && (
                  <div className="d-flex justify-content-center align-items-center loader">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Profile Image</label>

                    <div className="file-upload-wrapper">
                      <input
                        type="file"
                        className="file-upload"
                        id="user_profile_images"
                        name="user_profile_images"
                        accept="image/*"
                        onChange={this.handleImageChange}
                        multiple
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 image-perview">
                  {selectedImages.map((image, index) => (
                    <div className="form-group key={index}">
                      <figure>
                        <img
                          src={URL.createObjectURL(image.file)}
                          alt={`SelectedImage ${index + 1}`}
                          style={{
                            maxWidth: "200px",
                            maxHeight: "200px",
                          }}
                        />
                      </figure>
                      <label>
                        <input
                          type="radio"
                          name={`featuredImage`}
                          checked={image.is_featured === "1"}
                          onChange={() =>
                            this.handleFeaturedChange(index, "image")
                          }
                        />
                        Featured Image
                      </label>
                    </div>
                  ))}
                </div>

                <div className="col-12">
                  <button type="submit" className="btn-blue1">
                    Update Profile
                  </button>
                </div>
              </form>
              <div className="row image-gallery">
                {user_profile_images.length > 0 ? (
                  <div>
                    <h5 className="mt-4">User Profile Images</h5>
                    <hr />
                    <div className="row image-gallery image-container">
                      {user_profile_images.map((image, index) => (
                        <div
                          key={image._id}
                          className={`image-item ${
                            this.state.draggingIndex === index ? "dragging" : ""
                          }`}
                          draggable
                          onDragStart={(e) =>
                            this.handleDragStart(e, image._id, index)
                          }
                          onDragEnd={this.handleDragEnd}
                          onDrop={(e) => this.handleDrop(e, index)}
                          onDragOver={this.handleDragOver}
                        >
                          <img
                            src={`${process.env.REACT_APP_PROD_BUCKET_URL}/${image.image}`}
                            alt={`Image ${image._id}`}
                            className="img-fluid"
                          />
                          <button
                            className="delete-btn btn btn-danger"
                            onClick={() => this.handleDeleteImage(image._id)}
                          >
                            🗑️ Delete
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div>
                    <h5 className="mt-4">User Profile Images</h5>
                    <hr />
                    <p>No images found.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});

export default connect(mapStateToProps)(ExpertGallary);
