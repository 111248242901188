import React, { Component } from "react";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";

class EditUser extends Component {
  constructor(props) {
    super(props);
    const retrievedObject = getLocalStorageItem("myStylistAdmin");
    this.state = {
      adminUserData: {
        id: "",
        name: "",
        email: "",
        phone: "",
        role: "",
        status: "In-Active",
        password: "",
      },
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: {},
      token: retrievedObject ? retrievedObject.token : "",
      loading: false,
    };
  }

  componentDidMount() {
    const id = window.location.pathname.split("/").pop();
    this.getAdminUserResults(id);
  }

  getAdminUserResults = async (id) => {
    const { token } = this.state;
    const { AdminUserResults } = adminendPoints;

    try {
      const response = await APICaller(
        AdminUserResults,
        "POST",
        { id },
        "application/json",
        token
      );

      if (response.data.status === 200) {
        this.setState({
          adminUserData: response.data.data,
        });
      }
    } catch (error) {
      console.error("Error fetching admin user results:", error);
      this.setState({
        errorMsg: "An unexpected error occurred. Please try again.",
        successMsg: "",
        loading: false,
      });
    }
  };

  validateForm = () => {
    const { name, email, phone, role, password } = this.state.adminUserData;
    const errors = {};

    if (!name) errors.name = "Name is required.";
    else if (name.length < 5)
      errors.name = "Name must be at least 5 characters.";

    if (!email) errors.email = "Email is required.";
    else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
      errors.email = "Invalid email address.";

    if (!phone) errors.phone = "Phone is required.";
    else if (!/^\d{10}$/.test(phone))
      errors.phone = "Phone number must be 10 digits.";

    if (!role) errors.role = "Role is required.";

    if (!password) errors.password = "Password is required.";
    else if (password.length < 8)
      errors.password = "Password must be at least 8 characters.";

    return errors;
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      adminUserData: {
        ...prevState.adminUserData,
        [name]: value,
      },
    }));
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validateForm();

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      setTimeout(() => this.setState({ errors: {} }), 5000);
      return;
    }

    this.setState({ loading: true, errors: {} });
    const { adminUserData, token } = this.state;
    const { AdminAdminEditUser } = adminendPoints;

    try {
      const response = await APICaller(
        AdminAdminEditUser,
        "POST",
        adminUserData,
        "application/json",
        token
      );

      if (response.data.status === 200) {
        this.setState({
          successMsg: "Admin user updated successfully.",
          loading: false,
          redirect: true,
        });
      } else {
        this.setState({
          errorMsg: "An error occurred. Please try again.",
          loading: false,
        });
      }
    } catch (error) {
      console.error("Error updating admin user:", error);
      this.setState({
        errorMsg: "An unexpected error occurred. Please try again.",
        loading: false,
      });
    }
  };

  render() {
    const {
      adminUserData,
      errors,
      successMsg,
      errorMsg,
      loading,
      redirect,
      token,
      password,
      role,
    } = this.state;

    if (!token) return <Navigate to="/" />;
    if (redirect) return <Navigate to="/ManageAdminUsers" />;

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />

          <div className="card mb-4">
            <div className="card-body">
              <h4 className="card-title mb-4">Edit Admin User Information</h4>
              <form onSubmit={this.handleSubmit}>
                {errorMsg && (
                  <div className="alert alert-danger" role="alert">
                    {errorMsg}
                  </div>
                )}

                {successMsg && (
                  <div className="alert alert-success" role="alert">
                    {successMsg}
                  </div>
                )}

                {loading && (
                  <div className="d-flex justify-content-center align-items-center loader">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}

                <div className="form-area1 row g-4">
                  <div className="col-lg-6">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.name ? "is-invalid" : ""
                      }`}
                      id="name"
                      placeholder="Name"
                      name="name"
                      value={adminUserData.name || ""}
                      onChange={(e) =>
                        this.setState({
                          adminUserData: {
                            ...adminUserData,
                            name: e.target.value,
                          },
                        })
                      }
                    />
                    {errors.name && (
                      <div className="invalid-feedback">{errors.name}</div>
                    )}
                  </div>

                  <div className="col-lg-6">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className={`form-control ${
                        errors.email ? "is-invalid" : ""
                      }`}
                      id="email"
                      placeholder="Email"
                      name="email"
                      value={adminUserData.email || ""}
                      onChange={(e) =>
                        this.setState({
                          adminUserData: {
                            ...adminUserData,
                            email: e.target.value,
                          },
                        })
                      }
                    />
                    {errors.email && (
                      <div className="invalid-feedback">{errors.email}</div>
                    )}
                  </div>

                  <div className="col-lg-6">
                    <label htmlFor="phone" className="form-label">
                      Phone
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.phone ? "is-invalid" : ""
                      }`}
                      id="phone"
                      placeholder="Phone"
                      name="phone"
                      value={adminUserData.phone || ""}
                      onChange={(e) =>
                        this.setState({
                          adminUserData: {
                            ...adminUserData,
                            phone: e.target.value,
                          },
                        })
                      }
                    />
                    {errors.phone && (
                      <div className="invalid-feedback">{errors.phone}</div>
                    )}
                  </div>

                  <div className="col-lg-6">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      className={`form-control ${
                        errors.password ? "is-invalid" : ""
                      }`}
                      id="password"
                      placeholder="Password"
                      name="password"
                      onChange={(e) =>
                        this.setState({
                          adminUserData: {
                            ...adminUserData,
                            password: e.target.value,
                          },
                        })
                      }
                    />
                    {errors.password && (
                      <div className="invalid-feedback">{errors.password}</div>
                    )}
                  </div>

                  <div className="col-lg-12">
                    <label htmlFor="role" className="form-label">
                      Role
                    </label>
                    <select
                      className={`form-control ${
                        errors.role ? "is-invalid" : ""
                      }`}
                      id="role"
                      name="role"
                      value={adminUserData.role || ""}
                      onChange={this.handleInputChange}
                    >
                      <option value="">Select Role</option>
                      <option value="admin">Admin</option>
                      <option value="user">User</option>
                    </select>
                    {errors.role && (
                      <div className="invalid-feedback">{errors.role}</div>
                    )}
                  </div>
                </div>

                <button type="submit" className="btn btn-primary mt-3">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditUser;
