import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import moment from "moment";
import Select from "react-select";

class Amenities extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    this.state = {
      token: retrievedObject ? retrievedObject.token : "",
      amenities: {
        music: "No",
        parking_space: "No",
        credit_cards_accepted: "No",
        wi_fi: "No",
        pets_friendly: "No",
        child_friendly: "No",
        selfie_station: "No",
      },
      aawl: {
        toilet: "No",
        hygiene: "No",
        drinking_water: "No",
        ac: "No",
      },
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: {},
      loading: false,
      _id: "",
    };
  }

  componentDidMount() {
    const userid = window.location.pathname.split("/").pop();
    this.fetchUserData(userid);
  }

  fetchUserData = async (userid) => {
    this.setState({ loading: true });
    const { token } = this.state;

    const { AdminExpertUserDetails } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      const body = { token, userid: userid };
      const response = await APICaller(
        AdminExpertUserDetails,
        method,
        body,
        contentType
      );

      const { status, user } = response.data;

      if (status === 200 && user) {
        this.setState({
          loading: false,
          _id: userid,
          amenities: {
            music: user.amenities?.[0]?.music || "No",
            parking_space: user.amenities?.[0]?.parking_space || "No",
            credit_cards_accepted:
              user.amenities?.[0]?.credit_cards_accepted || "No",
            wi_fi: user.amenities?.[0]?.wi_fi || "No",
            pets_friendly: user.amenities?.[0]?.pets_friendly || "No",
            child_friendly: user.amenities?.[0]?.child_friendly || "No",
            selfie_station: user.amenities?.[0]?.selfie_station || "No",
          },
          aawl: {
            toilet: user.aawl?.[0]?.toilet || "No",
            hygiene: user.aawl?.[0]?.hygiene || "No",
            drinking_water: user.aawl?.[0]?.drinking_water || "No",
            ac: user.aawl?.[0]?.ac || "No",
          },
        });
      } else {
        this.setState({
          errorMsg: "Failed to fetch user data",
          loading: false,
        });
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      this.setState({ errorMsg: "Failed to fetch user data", loading: false });
    }
  };

  handleInputChange = (e) => {
    const { name, checked } = e.target;
    const value = checked ? "Yes" : "No";

    // Update state based on whether the field is in amenities or aawl
    this.setState((prevState) => {
      if (name in prevState.aawl) {
        return {
          aawl: {
            ...prevState.aawl,
            [name]: value,
          },
        };
      } else {
        return {
          amenities: {
            ...prevState.amenities,
            [name]: value,
          },
        };
      }
    });
  };

  validateForm = () => {
    const { amenities, aawl } = this.state;
    const newErrors = {};

    if (!Object.values(amenities).some((value) => value === "Yes")) {
      newErrors.amenities = "At least one amenity must be selected.";
    }

    if (!Object.values(aawl).some((value) => value === "Yes")) {
      newErrors.aawl = "At least one AAWL option must be selected.";
    }

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    const newErrors = this.validateForm();
    if (Object.keys(newErrors).length > 0) {
      this.setState({ errors: newErrors, loading: false });
      return;
    }

    const { amenities, aawl, token, _id } = this.state;
    const body = { id: _id, token, amenities, aawl };
    const contentType = "application/json";

    try {
      const response = await APICaller(
        adminendPoints.OnboardingSaveAmenities,
        "POST",
        body,
        contentType
      );

      if (response.status === 200) {
        this.setState({
          successMsg: "Amenities updated successfully.",
          loading: false,
        });
      } else {
        this.setState({ errorMsg: "Error saving amenities.", loading: false });
      }
    } catch (error) {
      console.error("Error making data call:", error);
      this.setState({ errorMsg: "Error making data call.", loading: false });
    }
  };

  renderAmenities = () => {
    const { amenities, aawl } = this.state;

    const allAmenities = [
      { name: "music", label: "Music", img: "music-icon.png" },
      { name: "parking_space", label: "Parking Space", img: "parking.png" },
      {
        name: "credit_cards_accepted",
        label: "Credit Cards Accepted",
        img: "credit.png",
      },
      { name: "wi_fi", label: "Wi-Fi", img: "wifi.png" },
      { name: "pets_friendly", label: "Pets Friendly", img: "pets.png" },
      { name: "child_friendly", label: "Child Friendly", img: "child.png" },
      { name: "selfie_station", label: "Selfie Station", img: "selfie.png" },
    ];

    const aawlFields = [
      { name: "toilet", label: "Toilet", img: "music-icon.png" },
      {
        name: "drinking_water",
        label: "Drinking Water",
        img: "music-icon.png",
      },
      { name: "ac", label: "AC", img: "music-icon.png" },
    ];

    return (
      <ul className="Amenities-bus">
        {allAmenities.map((amenity) => (
          <li
            key={amenity.name}
            className={amenities[amenity.name] === "Yes" ? "amn-active" : ""}
          >
            <label>
              <input
                type="checkbox"
                name={amenity.name}
                checked={amenities[amenity.name] === "Yes"}
                onChange={this.handleInputChange}
              />
              <i className="fa fa-check" aria-hidden="true"></i>
              <div className="amnt-content">
                <span>{amenity.label}</span>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/img/${amenity.img}`}
                  alt={amenity.label}
                />
              </div>
            </label>
          </li>
        ))}

        {aawlFields.map((field) => (
          <li
            key={field.name}
            className={aawl[field.name] === "Yes" ? "amn-active" : ""}
          >
            <label>
              <input
                type="checkbox"
                name={field.name}
                checked={aawl[field.name] === "Yes"}
                onChange={this.handleInputChange}
              />
              <i className="fa fa-check" aria-hidden="true"></i>
              <div className="amnt-content">
                <span>{field.label}</span>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/img/${field.img}`}
                  alt={field.label}
                />
              </div>
            </label>
          </li>
        ))}
      </ul>
    );
  };

  render() {
    const { errors, successMsg, errorMsg, loading } = this.state;

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />

          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">Manage Amenities</h5>

              {successMsg && <p className="success">{successMsg}</p>}
              {errorMsg && <p className="error">{errorMsg}</p>}
              {Object.keys(errors).map((key) => (
                <p key={key} className="error">
                  {errors[key]}
                </p>
              ))}

              <form onSubmit={this.handleSubmit}>
                <div className="form-group mb-3">
                  <h6>Amenities</h6>
                  {this.renderAmenities()}
                </div>

                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading}
                  >
                    {loading ? "Saving..." : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Amenities;
