import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import Select from "react-select";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate } from "../../utils/commonFunction";

class CreateCampaign extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    this.state = {
      redirect: false,
      campaignType: "",
      fileName: "",
      selectedFile: null,
      previewURL: null,
      title: "",
      description: "",
      campaignCode: "",
      startDate: "",
      endDate: "",
      services: [],
      subServicesWithPrice: [],
      campaign: {
        service_id: [],
        sub_service_id: [],
      },
      errors: {},
      errorMsg: "",
      successMsg: "",
      loading: false,
      cities: [],
      selectedCity: null,
      usersResults: [],
      token: retrievedObject ? retrievedObject.token : "",
    };
  }

  componentDidMount() {
    this.getAllServices();
    this.fetchCity();
  }

  fetchCity = async () => {
    const { token } = this.state;
    const { AdminCityListNew } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      const body = { token };
      const response = await APICaller(
        AdminCityListNew,
        method,
        JSON.stringify(body),
        contentType,
        token
      );

      const { data, status } = response.data;

      if (status === 200) {
        const citiesData = data.map((city) => ({
          value: city.city_id,
          label: city.city_name,
        }));

        const selectedCities = citiesData || [];
        this.setState({ cities: selectedCities });
      } else {
        console.error("Failed to fetch cities");
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  getAllServices = async () => {
    const { token } = this.state;
    const { AdminServicesListMobile } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const stateUrl = `${AdminServicesListMobile}`;

    try {
      const servicesResponse = await APICaller(
        stateUrl,
        method,
        "",
        contentType,
        token
      );

      const { services, status } = servicesResponse.data;
      if (status === 200) {
        this.setState({ services: services });
      }
    } catch (error) {
      console.log("error making data call --> ", error);
    }
  };

  fetchSubServices = async (serviceIds) => {
    const { token } = this.state;
    const { AdminSubServicesListMobile } = adminendPoints;
    const method = "POST";
    const serviceIdsString = serviceIds.join(",");

    const body = {
      serviceIds: serviceIdsString,
    };
    const contentType = "application/json";

    try {
      const response = await APICaller(
        AdminSubServicesListMobile,
        method,
        body,
        contentType,
        token
      );

      const { status, subServices } = response.data;
      if (status === 200) {
        const subServicesWithPrice = subServices.map((subService) => ({
          ...subService,
          price: 0,
          selected: false,
        }));

        this.setState({ subServicesWithPrice });
      } else {
        this.setState(
          {
            errorMsg: "Failed to fetch sub-services. Please try again.",
            successMsg: "",
          },
          () => {
            setTimeout(() => {
              this.setState({ errorMsg: "" });
            }, 3000);
          }
        );
      }
    } catch (error) {
      console.log("error making data call --> ", error);
      this.setState({
        errorMsg:
          "An error occurred while creating the category. Please try again.",
        successMsg: "",
      });
    }
  };

  handleSelectChange = (name, selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    const commaSeparatedValues = selectedValues.join(",");
    this.setState((prevState) => ({
      campaign: {
        ...prevState.campaign,
        [name]: commaSeparatedValues,
      },
    }));

    if (name === "service_id" && selectedValues.length > 0) {
      this.fetchSubServices(selectedValues);
    }
  };

  toggleSubService = (subServiceId) => {
    const subServicesWithPrice = this.state.subServicesWithPrice.map(
      (subService) => ({
        ...subService,
        selected:
          subService._id === subServiceId
            ? !subService.selected
            : subService.selected,
      })
    );

    this.setState({ subServicesWithPrice });
  };

  handlePriceChange = (subServiceId, price) => {
    const subServicesWithPrice = this.state.subServicesWithPrice.map(
      (subService) => ({
        ...subService,
        price:
          subService._id === subServiceId
            ? parseFloat(price)
            : subService.price,
      })
    );

    this.setState({ subServicesWithPrice });
  };

  validateForm = () => {
    const {
      title,
      description,
      campaignCode,
      startDate,
      endDate,
      campaign,
      selectedCity,
      subServicesWithPrice,
    } = this.state;

    const newErrors = {};

    if (!title) {
      newErrors.title = "Campaign Title is required.";
    }

    if (!description) {
      newErrors.description = "Description is required.";
    }

    if (!campaignCode) {
      newErrors.campaignCode = "Campaign Code is required.";
    }

    if (!startDate) {
      newErrors.startDate = "Start Date is required.";
    }

    if (!endDate) {
      newErrors.endDate = "End Date is required.";
    }

    if (!campaign.service_id.length) {
      newErrors.service_id = "Service Name is required.";
    }

    const selectedSubServices = subServicesWithPrice.filter(
      (subService) => subService.selected
    );
    if (selectedSubServices.length === 0) {
      newErrors.sub_service_id =
        "At least one Sub-Service with price is required.";
    }

    if (!selectedCity.value) {
      newErrors.selectedCity.value = "Select City is required.";
    }

    return newErrors;
  };

  handleCityChange = (event) => {
    const selectedCityValue = event.target.value;
    const selectedCity = this.state.cities.find(
      (city) => city.value === selectedCityValue
    );

    const selectedServiceIds = this.state.campaign.service_id;

    const selectedSubServiceIds = this.state.subServicesWithPrice
      .filter((subService) => subService.selected)
      .map((subService) => subService._id);

    this.setState({ selectedCity }, () => {
      if (selectedCity) {
        this.getUserList(
          selectedServiceIds,
          selectedSubServiceIds,
          selectedCity.value // Pass the value of the selected city
        );
      }
    });
  };

  getUserList = async (
    selectedServiceIds,
    selectedSubServiceIds,
    selectedCityIds
  ) => {
    const { token } = this.state;
    const { AdminGetUsersByServiceAndSubService } = adminendPoints;
    const method = "POST";

    const body = {
      serviceId: Array.isArray(selectedServiceIds)
        ? selectedServiceIds
        : [selectedServiceIds],
      subServiceId: selectedSubServiceIds,
      city: selectedCityIds,
    };
    const contentType = "application/json";

    try {
      const userResponse = await APICaller(
        AdminGetUsersByServiceAndSubService,
        method,
        body,
        contentType,
        token
      );

      const { users, status } = userResponse.data;
      if (status === 200) {
        this.setState({
          usersResults: users.map((user) => ({ ...user, selected: true })),
        });
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  handleFileSelect = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        selectedFile: file,
        previewURL: reader.result,
        fileName: file.name, // Set the fileName state to the actual file name
      });
    };
    reader.readAsDataURL(file);
  };

  handleFileRemove = () => {
    document.getElementById("fileName").value = "";

    this.setState({
      selectedFile: null,
      previewURL: null,
      fileName: "",
    });
  };

  handleUserClick = (userId) => {
    const updatedUsers = this.state.usersResults.map((user) =>
      user._id === userId ? { ...user, selected: !user.selected } : user
    );

    this.setState({ usersResults: updatedUsers });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const errors = this.validateForm();
    this.setState({ loading: true });
    const { token } = this.state;
    if (Object.keys(errors).length > 0) {
      this.setState({
        errors: errors,
        errorMsg: "Please fill in all required fields.",
        successMsg: "",
        loading: false,
      });
      return;
    }

    const selectedUserIds = this.state.usersResults
      .filter((user) => user.selected)
      .map((user) => user._id);

    const formData = new FormData();
    const { AdminAddCampaign } = adminendPoints;

    const selectedSubServices = this.state.subServicesWithPrice.filter(
      (subService) => subService.selected
    );

    selectedSubServices.forEach((subService, index) => {
      formData.append(`sub_services[${index}]`, subService._id);
      formData.append(`prices[${index}]`, subService.price);
    });

    selectedUserIds.forEach((userId, index) => {
      formData.append(`userIds[${index}]`, userId);
    });

    formData.append("cities", this.state.selectedCity.value);
    formData.append("services[]", this.state.campaign.service_id);
    formData.append("fileName", this.state.selectedFile);
    formData.append("title", this.state.title);
    formData.append("description", this.state.description);
    formData.append("campaignCode", this.state.campaignCode);
    formData.append("startDate", this.state.startDate);
    formData.append("endDate", this.state.endDate);
    formData.append("campaignType", this.state.campaignType);

    try {
      const method = "POST";
      const body = formData;

      try {
        const response = await APICaller(
          AdminAddCampaign,
          method,
          body,
          {},
          token
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsg: "Campaign created successfully.",
              errorMsg: "",
            },
            () => {
              this.setState({
                loading: false,
              });
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
              }, 5000);
            }
          );
        } else if (status === 409) {
          this.setState(
            {
              errorMsg: "This Campaign Code is already exist",
              successMsg: "",
              loading: false,
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to create service. Please try again.",
              successMsg: "",
              loading: false,
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the service. Please try again.",
          successMsg: "",
          loading: false,
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);

      this.setState({
        errorMsg: "Failed to submit form. Please try again.",
        successMsg: "",
      });
    }
  };

  render() {
    const {
      previewURL,
      title,
      description,
      campaignCode,
      startDate,
      endDate,
      services,
      subServicesWithPrice,
      campaign,
      errors,
      errorMsg,
      successMsg,
      loading,
      states,
      districts,
      cities,
      selectedState,
      selectedDistrict,
      selectedCity,
      usersResults,
      campaignType,
      redirect,
    } = this.state;

    if (redirect) {
      return <Navigate to="/ManageCampaigns" />;
    }

    const serviceOptions = services.map((service) => ({
      label: service.service_name,
      value: service._id,
    }));

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="main-right-content-inner">
            <div className="row">
              <div className="col-lg-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-4">
                      <h4 className="card-title mb-0">Generate Campaign</h4>
                      <div className="ms-auto">
                        <Link to="/ManageCampaigns" className="btn-light1 ms-2">
                          <i class="fa-solid fa-arrow-left-long me-2"></i>
                          <span>Back</span>
                        </Link>
                      </div>
                    </div>

                    <form onSubmit={this.handleSubmit}>
                      <div className="">
                        {errorMsg && (
                          <div className="alert alert-danger" role="alert">
                            {errorMsg}
                          </div>
                        )}
                        {successMsg && (
                          <div className="alert alert-success" role="alert">
                            {successMsg}
                          </div>
                        )}

                        {loading && (
                          <div className="d-flex justify-content-center align-items-center loader">
                            <div className="spinner-border" role="status">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        )}

                        <div className="form-area1 row g-4">
                          <div className="col-lg-12">
                            <label htmlFor="service_id" className="form-label">
                              Service Name
                            </label>

                            <Select
                              id="service_id"
                              name="service_id"
                              options={serviceOptions}
                              isMulti
                              value={serviceOptions.filter((option) =>
                                campaign.service_id.includes(option.value)
                              )}
                              onChange={(selectedOptions) =>
                                this.handleSelectChange(
                                  "service_id",
                                  selectedOptions
                                )
                              }
                            />

                            {errors.service_id && (
                              <p className="error">{errors.service_id}</p>
                            )}
                          </div>

                          <div className="col-lg-12">
                            <label
                              htmlFor="sub_service_id"
                              className="form-label"
                            >
                              Sub-Service Name
                            </label>
                            <div className="row">
                              {subServicesWithPrice.map((subService) => (
                                <div
                                  className="col-2 d-flex align-items-center mb-2"
                                  key={subService._id}
                                >
                                  <label>
                                    <input
                                      type="checkbox"
                                      className="form-check-input me-2"
                                      checked={subService.selected}
                                      onChange={() =>
                                        this.toggleSubService(subService._id)
                                      }
                                    />
                                    {subService.sub_service_name}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control small ms-3"
                                    value={subService.price}
                                    onChange={(e) =>
                                      this.handlePriceChange(
                                        subService._id,
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              ))}
                            </div>

                            {errors.sub_service_id && (
                              <p className="error">{errors.sub_service_id}</p>
                            )}
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <label
                                htmlFor="city"
                                className={`form-label ${
                                  errors.selectedCity ? "is-invalid" : ""
                                }`}
                              >
                                Select City:
                              </label>
                              <select
                                id="city"
                                value={selectedCity ? selectedCity.value : ""}
                                onChange={this.handleCityChange}
                                className="form-control"
                              >
                                <option value="">Select City</option>
                                {cities.map((city) => (
                                  <option key={city.value} value={city.value}>
                                    {city.label}
                                  </option>
                                ))}
                              </select>

                              {errors.selectedCity && (
                                <div className="invalid-feedback">
                                  {errors.selectedCity}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="title">Campaign Title</label>
                              <input
                                placeholder="Campaign Title"
                                type="text"
                                className="form-control"
                                id="title"
                                name="title"
                                value={title}
                                onChange={this.handleInputChange}
                              />
                              {errors.title && (
                                <p className="error">{errors.title}</p>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="title">Campaign Code</label>
                              <input
                                placeholder="Campaign Code"
                                type="text"
                                className="form-control"
                                id="campaignCode"
                                name="campaignCode"
                                value={campaignCode}
                                onChange={this.handleInputChange}
                              />
                              {errors.campaignCode && (
                                <p className="error">{errors.campaignCode}</p>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="startDate">Start Date</label>
                              <input
                                placeholder="Start Date"
                                type="date"
                                className="form-control"
                                id="startDate"
                                name="startDate"
                                value={startDate}
                                onChange={this.handleInputChange}
                              />
                              {errors.startDate && (
                                <p className="error">{errors.startDate}</p>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="endDate">End Date</label>
                              <input
                                placeholder="End Date"
                                type="date"
                                className="form-control"
                                id="endDate"
                                name="endDate"
                                value={endDate}
                                onChange={this.handleInputChange}
                              />
                              {errors.endDate && (
                                <p className="error">{errors.endDate}</p>
                              )}
                            </div>
                          </div>

                          <div className="col">
                            <div className="upload-img-box">
                              <i className="fa-solid fa-file-arrow-up"></i>
                              <p className="mb-0">Upload Image</p>
                              <input
                                type="file"
                                className="form-control"
                                id="fileName"
                                placeholder="Absence through"
                                name="fileName"
                                accept="image/*"
                                onChange={this.handleFileSelect}
                              />
                              {previewURL && (
                                <div>
                                  <img
                                    src={previewURL}
                                    alt="Category Preview"
                                    style={{ width: "100px", height: "100px" }}
                                  />
                                  <button
                                    type="button"
                                    onClick={this.handleFileRemove}
                                  >
                                    Remove
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="form-group">
                              <label htmlFor="description">Description</label>
                              <textarea
                                placeholder="Description"
                                className="form-control"
                                id="description"
                                name="description"
                                value={description}
                                onChange={this.handleInputChange}
                              />
                              {errors.description && (
                                <p className="error">{errors.description}</p>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="form-group">
                              <label htmlFor="description">Campaign Type</label>
                              <select
                                className="form-control"
                                id="campaignType"
                                name="campaignType"
                                value={this.state.campaignType}
                                onChange={this.handleInputChange}
                              >
                                <option value="">Select Campaign Type</option>
                                <option value="Marketing">Marketing</option>
                                <option value="Promotion">Promotion</option>
                                <option value="Event">Event</option>
                                <option value="Discount">Discount</option>
                                <option value="Other">Other</option>
                              </select>

                              {errors.campaignType && (
                                <p className="error">{errors.campaignType}</p>
                              )}
                            </div>
                          </div>

                          {usersResults.length > 0 && (
                            <div className="col-lg-12">
                              <div className="form-group">
                                <label htmlFor="description">User List</label>
                                <div className="user-grid-outer">
                                  <div className="row">
                                    {usersResults.map((user) => (
                                      <div
                                        className="col-3"
                                        key={user._id}
                                        onClick={() =>
                                          this.handleUserClick(user._id)
                                        }
                                      >
                                        <div
                                          className={`user-grid-item text-center mb-3 ${
                                            user.selected ? "active" : ""
                                          }`}
                                        >
                                          <figure className="mx-auto mb-2 user-img user-img-100 online">
                                            <img
                                              className="w-100"
                                              src="https://admin.mystylist.in/assets/img/dummy-user.png"
                                              alt=""
                                              title=""
                                            />
                                          </figure>
                                          <p className="fs-18 mb-0">
                                            <strong className="tc-6">
                                              {user.name}
                                            </strong>
                                          </p>
                                          <p className="fs-14 tc-7 mb-0">
                                            Customer Since:{" "}
                                            {formatDate(user.createdAt)}
                                          </p>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="col-12">
                            <button type="submit" className="btn-blue">
                              Update Changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});

export default connect(mapStatetoProps)(CreateCampaign);
