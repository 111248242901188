import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate } from "../../utils/commonFunction";
import ViewOffers from "./viewOffers";
import { withPermissions } from "../context/PermissionsContext";

class Offers extends Component {
  constructor(props) {
    super(props);

    var retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      token: retrievedObject ? retrievedObject.token : "",
      results: [],
      emailErrorMsg: "",
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      successMsg: "",
      errorMsg: "",
      activeTab: "All",
      search: "",
      sort: "",
      isVisibleView: false,
      isVisibleAdd: false,
      status: "",
      offerDetailsresults: "",
      loading: true,
      featured_image_url: "https://mystylist-media.s3.amazonaws.com",
    };
  }

  componentDidMount() {
    this.getAllOffers();
  }

  getAllOffers = () => {
    this.setState({ loading: true });
    const { token, currentPage, itemsPerPage } = this.state;
    const { AdminOfferList } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    let queryParams = `page=${currentPage}&limit=${itemsPerPage}`;

    const url = `${AdminOfferList}${queryParams ? `?${queryParams}` : ""}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, offers, totalItems } = response.data;
        if (status === 200) {
          this.setState({
            results: offers,
            totalItems: totalItems,
            loading: false,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
              loading: false,
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.error("Error making data call:", error);
        this.setState({
          loading: false,
        });
      });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      this.getAllOffers();
    });
  };

  handleDelete = async (itemId) => {
    const { token } = this.state;
    const { AdminFaqDelete } = adminendPoints;
    const method = "POST";

    const body = {
      itemId,
    };
    const contentType = "application/json";

    try {
      const response = await APICaller(
        AdminFaqDelete,
        method,
        body,
        contentType,
        token
      );

      const { status } = response.data;
      if (status === 200) {
        this.setState(
          (prevState) => ({
            results: prevState.results.filter((item) => item._id !== itemId),
            successMsg: "Faq deleted successfully.",
            errorMsg: "",
          }),
          () => {
            setTimeout(() => {
              this.setState({ successMsg: "" }); // Clear the success message after 3 seconds
            }, 3000);
          }
        );
      } else {
        this.setState(
          {
            errorMsg: "Failed to delete FAQ. Please try again.",
            successMsg: "",
          },
          () => {
            setTimeout(() => {
              this.setState({ errorMsg: "" });
            }, 3000);
          }
        );
      }
    } catch (error) {
      console.log("error making data call --> ", error);
      this.setState({
        errorMsg:
          "An error occurred while creating the offers. Please try again.",
        successMsg: "",
      });
    }
  };

  handleTabClick = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab, currentPage: 1 }, () => {
        this.getAllOffers();
      });
    }
  };

  handleSearchChange = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  handleCloseViewContent = () => {
    this.setState({ isVisibleView: false });
  };

  viewCategorytoggle = (offerId) => {
    this.getOfferDetails(offerId);
    this.setState((prevState) => ({
      isVisibleView: !prevState.isVisibleView,
    }));
  };

  getOfferDetails = (id) => {
    const { token } = this.state;
    const { AdminOfferDetails } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const url = `${AdminOfferDetails}/${id}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, offer } = response.data;

        if (status === 200) {
          this.setState({
            offerDetailsresults: offer,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  handleCloseAddContent = () => {
    this.setState({ isVisibleAdd: false });
  };

  addCategorytoggle = () => {
    this.setState((prevState) => ({
      isVisibleAdd: !prevState.isVisibleAdd,
    }));
  };

  handleToggleCheckBox = async (offerId, currentStatus, expert_id) => {
    const { token } = this.state;
    const { AdminOfferStatusUpdate } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    const newStatus = currentStatus === "Active" ? "In-Active" : "Active";

    const body = {
      offerId,
      status: newStatus,
      expert_id: expert_id,
    };

    try {
      const response = await APICaller(
        AdminOfferStatusUpdate,
        method,
        body,
        contentType,
        token
      );

      const { status, message } = response.data;
      if (status === 200) {
        this.setState((prevState) => ({
          results: prevState.results.map((offer) =>
            offer._id === offerId ? { ...offer, status: newStatus } : offer
          ),
          successMsg: `Offer Status is ${newStatus}`,
          errorMsg: "",
        }));

        setTimeout(() => {
          this.setState({ successMsg: "" });
        }, 2000);
      } else {
        console.error("Failed to update offer status");
        this.setState({
          errorMsg: message,
          successMsg: "", // Clear any previous success messages
        });
      }
    } catch (error) {
      console.error("Error making data call:", error);
      this.setState({
        errorMsg: "An error occurred while updating offer status.",
        successMsg: "", // Clear any previous success messages
      });
    }
  };

  openImageModal = (imageUrl) => {
    this.setState({
      isImageModalOpen: true,
      selectedImageUrl: imageUrl,
    });
  };

  closeImageModal = () => {
    this.setState({
      isImageModalOpen: false,
      selectedImageUrl: "",
    });
  };

  render() {
    const {
      results,
      token,
      search,
      currentPage,
      itemsPerPage,
      successMsg,
      errorMsg,
      sort,
      isVisibleView,
      isVisibleAdd,
      offerDetailsresults,
      loading,
      featured_image_url,
    } = this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    const { permissions } = this.props;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const filteredAndSortedItems = Array.isArray(results)
      ? [...results]
          .filter((user) => {
            // Extract values from the nested structure
            const serviceName =
              typeof user.service?.service_id?.service_name === "string"
                ? user.service.service_id.service_name
                : "";
            const offerName =
              typeof user.offer_name === "string" ? user.offer_name : "";
            const originalPrice =
              typeof user.originalPrice === "string" ||
              typeof user.originalPrice === "number"
                ? user.originalPrice.toString()
                : "";
            const subServiceName =
              typeof user.sub_services?.sub_service_id?.sub_service_name ===
              "string"
                ? user.sub_services.sub_service_id.sub_service_name
                : "";

            // Log extracted values for debugging
            console.log({
              serviceName,
              offerName,
              originalPrice,
              subServiceName,
              search,
            });

            const matchesSearch =
              serviceName.toLowerCase().includes(search.toLowerCase()) ||
              offerName.toLowerCase().includes(search.toLowerCase()) ||
              originalPrice.includes(search) ||
              subServiceName.toLowerCase().includes(search.toLowerCase());

            console.log(`Does user match search? ${matchesSearch}`);
            return matchesSearch;
          })
          .sort((a, b) => {
            return sort === "asc"
              ? a._id.localeCompare(b._id)
              : b._id.localeCompare(a._id);
          })
      : [];

    const currentItems = filteredAndSortedItems.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    const totalPages = Math.ceil(filteredAndSortedItems.length / itemsPerPage);

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="main-right-content-inner">
            <div className="card mb-4">
              <div className="card-body pb-0">
                <div className="d-flex align-items-center mb-3">
                  <div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">Home</a>
                      </li>
                      <li className="breadcrumb-item active">
                        All Offers List
                      </li>
                    </ol>
                    <h4 className="card-title mb-0">All Offers List</h4>
                  </div>
                  {permissions?.offers?.add && (
                    <div className="ms-auto">
                      <Link to="/AddOffers" className="btn-blue ms-2">
                        <i className="fa-solid fa-plus me-2"></i>Genrate Offer
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {loading && (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}

            {successMsg && (
              <div className="alert alert-success" role="alert">
                {successMsg}
              </div>
            )}

            {errorMsg && (
              <div className="alert alert-danger" role="alert">
                {errorMsg}
              </div>
            )}
            <div className="card mb-4">
              <div className="card-body">
                <div className="row align-items-center mb-3">
                  <div className="col-lg-5">
                    <div className="form-control-group-icon search">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here..."
                        value={search}
                        onChange={this.handleSearchChange}
                      />
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="form-control-group-icon ms-3">
                        <select
                          className="form-select w-auto"
                          value={sort}
                          onChange={(e) =>
                            this.setState({ sort: e.target.value })
                          }
                        >
                          <option value="asc">Ascending</option>
                          <option value="desc">Descending</option>
                        </select>
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <table className="table align-middle mb-4">
                  <thead>
                    <tr>
                      <th>SN.</th>
                      <th>Offer Name</th>
                      <th>Service Name</th>
                      <th>Sub Service Name</th>
                      <th>Price</th>
                      <th>start_date</th>
                      <th>End Date</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.length === 0 ? (
                      <tr>
                        <td colSpan="7" className="text-center">
                          No users available for the selected categories.
                        </td>
                      </tr>
                    ) : (
                      currentItems.map((offers, index) => (
                        <tr key={index}>
                          <td>{index + 1}.</td>
                          <td>{offers.offer_name}</td>
                          <td>
                            {offers.service?.service_id?.service_name || "N/A"}
                          </td>
                          <td>
                            {offers.sub_services?.sub_service_id
                              ?.sub_service_name || "N/A"}
                          </td>
                          <td>{offers.sub_services?.price || "N/A"}</td>
                          <td>{formatDate(offers.start_date)}</td>
                          <td>{formatDate(offers.end_date)}</td>

                          {permissions?.offers?.status && (
                            <td className="text-center">
                              {offers.status && (
                                <div className="form-check form-switch d-inline-block p-0 m-0">
                                  <input
                                    className="form-check-input m-0"
                                    type="checkbox"
                                    role="switch"
                                    checked={offers.status === "Active"}
                                    onChange={() =>
                                      this.handleToggleCheckBox(
                                        offers._id,
                                        offers.status,
                                        offers.expert_id
                                      )
                                    }
                                  />
                                </div>
                              )}
                            </td>
                          )}
                          <td className="text-center">
                            <Link
                              to="#"
                              className="icon-btn"
                              onClick={() =>
                                this.openImageModal(
                                  featured_image_url +
                                    "/" +
                                    offers.featured_image
                                )
                              }
                            >
                              <img
                                src="assets/img/icon-gallery.svg"
                                alt="View Image"
                                title="View Banner Image"
                              />
                            </Link>
                            {permissions?.offers?.edit && (
                              <Link
                                to={`/EditOffers/${offers._id}`}
                                title="Edit"
                                className="icon-btn"
                              >
                                <img
                                  src="assets/img/icon-edit.svg"
                                  alt=""
                                  title=""
                                />
                              </Link>
                            )}
                            {permissions?.offers?.delete && (
                              <Link
                                onClick={() =>
                                  this.viewCategorytoggle(offers._id)
                                }
                                title="View"
                                className="icon-btn"
                              >
                                <img
                                  src="assets/img/icon-view.svg"
                                  alt=""
                                  title="View"
                                />
                              </Link>
                            )}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                  {this.state.isImageModalOpen && (
                    <div
                      className="modal"
                      tabIndex="-1"
                      role="dialog"
                      style={{ display: "block" }}
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-body">
                            <span
                              className="close"
                              onClick={this.closeImageModal}
                            >
                              &times;
                            </span>
                            <img
                              src={this.state.selectedImageUrl}
                              alt="View Image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </table>
                <div className="d-flex align-items-center justify-content-end p-3">
                  {currentPage > 1 && (
                    <button
                      className="pagination-prev me-2"
                      onClick={(e) => this.handlePageChange(currentPage - 1, e)}
                    >
                      <i className="fa-solid fa-angle-left"></i>
                    </button>
                  )}

                  <ul className="pagination m-0">
                    {Array.from({ length: totalPages }).map((_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={(e) => this.handlePageChange(index + 1, e)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}
                  </ul>

                  {currentPage < totalPages && (
                    <button
                      className="pagination-next"
                      onClick={(e) => this.handlePageChange(currentPage + 1, e)}
                    >
                      <i className="fa-solid fa-angle-right"></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isVisibleView && (
          <ViewOffers
            isVisibleView={isVisibleView}
            handleCloseViewContent={this.handleCloseViewContent}
            offerDetailsresults={offerDetailsresults}
            formatDate={formatDate}
          />
        )}
      </div>
    );
  }
}

export default withPermissions(Offers);
