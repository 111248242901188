import React, { createContext, Component } from "react";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";

// Create context for permissions
const PermissionsContext = createContext();

// PermissionsProvider component to manage permissions state
class PermissionsProvider extends Component {
  constructor(props) {
    super(props);

    // Retrieve the admin data from localStorage
    const retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      id: retrievedObject ? retrievedObject.id : "",
      token: retrievedObject ? retrievedObject.token : "",
      permissions: [],
      loading: false,
      errorMsg: "",
    };
  }

  componentDidMount() {
    const { id } = this.state;
    if (id) {
      this.fetchPermissions(id); // Fetch permissions if the ID exists
    }
  }

  // Fetch permissions from the API
  fetchPermissions = async (adminId) => {
    this.setState({ loading: true });

    const { token } = this.state;
    const { AdminViewAdminPermission } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      const body = { token, id: adminId };
      const response = await APICaller(
        AdminViewAdminPermission,
        method,
        body,
        contentType,
        token
      );

      const { status, data } = response.data;

      if (status === 200) {
        this.setState({
          permissions: data.permissions, // Store permissions data
          loading: false,
        });
      } else {
        this.setState({
          errorMsg: "Failed to load permissions.",
          loading: false,
        });
      }
    } catch (error) {
      this.setState({
        errorMsg: "Error fetching permissions.",
        loading: false,
      });
    }
  };

  render() {
    const { permissions, loading, errorMsg } = this.state;

    return (
      <PermissionsContext.Provider value={{ permissions, loading, errorMsg }}>
        {this.props.children}
      </PermissionsContext.Provider>
    );
  }
}

// Higher-order component to provide permissions to wrapped component
const withPermissions = (WrappedComponent) => {
  return function (props) {
    return (
      <PermissionsContext.Consumer>
        {(context) => <WrappedComponent {...props} {...context} />}
      </PermissionsContext.Consumer>
    );
  };
};

export { PermissionsProvider, withPermissions };
