import React, { Component } from "react";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";

class AddAdminUser extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    this.state = {
      name: "",
      email: "",
      phone: "",
      role: "",
      status: "In-Active",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: {},
      token: retrievedObject ? retrievedObject.token : "",
      status: "In-Active",
      loading: false,
      password: "",
    };
  }

  validateForm = () => {
    const { name, email, phone, role, password } = this.state;
    const newErrors = {};

    if (!name) {
      newErrors.name = "Name is required.";
    } else if (name.length < 5) {
      newErrors.name = "Name must be at least 5 characters.";
    }
    if (!email) {
      newErrors.email = "Email is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      newErrors.email = "Enter a valid email address.";
    }

    if (!phone) {
      newErrors.phone = "Phone is required.";
    } else if (!/^\d{10}$/.test(phone)) {
      newErrors.phone = "Phone number must be 10 digits.";
    }

    if (!role) {
      newErrors.role = "Role is required.";
    }

    if (!password) {
      newErrors.password = "Password is required.";
    } else {
      if (password.length < 8) {
        newErrors.password = "Password must be at least 8 characters.";
      }
      if (!/[A-Z]/.test(password)) {
        newErrors.password =
          "Password must contain at least one uppercase letter.";
      }
      if (!/[a-z]/.test(password)) {
        newErrors.password =
          "Password must contain at least one lowercase letter.";
      }
      if (!/[0-9]/.test(password)) {
        newErrors.password = "Password must contain at least one number.";
      }
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        newErrors.password =
          "Password must contain at least one special character.";
      }
    }

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();
    this.setState({ loading: true });

    if (Object.keys(newErrors).length > 0) {
      this.setState(
        { errorMsg: "", successMsg: "", errors: newErrors, loading: false },
        () => {
          setTimeout(() => this.setState({ errors: {} }), 5000);
        }
      );
      return;
    }

    this.setState({ errors: {} });
    const { name, email, phone, role, token, status, password } = this.state;
    const { AdminRegister } = adminendPoints;

    const method = "POST";
    const body = {
      name,
      email,
      phone,
      role,
      status,
      password,
    };
    const contentType = "application/json";

    try {
      const response = await APICaller(
        AdminRegister,
        method,
        body,
        contentType,
        token
      );

      const { status } = response.data;
      if (status === 200) {
        this.setState(
          {
            successMsg: "Admin user created successfully.",
            errorMsg: "",
            loading: false,
          },
          () => {
            this.setState({
              name: "",
              email: "",
              phone: "",
              role: "",
              password: "",
            });
            this.props.reloadListing(); // This should work now
            this.props.handleCloseAddContent(); // This should work now
            setTimeout(
              () => this.setState({ successMsg: "", redirect: true }),
              5000
            );
          }
        );
      } else {
        this.setState(
          {
            errorMsg: "An error occurred. Please try again.",
            successMsg: "",
            loading: false,
          },
          () => {
            setTimeout(() => this.setState({ errorMsg: "" }), 3000);
          }
        );
      }
    } catch (error) {
      console.error("Error creating admin user: ", error);
      this.setState({
        errorMsg: "An unexpected error occurred. Please try again.",
        successMsg: "",
        loading: false,
      });
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleTypeChange = (event) => {
    const { value } = event.target; // Get the selected value from the event
    this.setState({ role: value }); // Update the state with the selected value
  };

  render() {
    const {
      name,
      email,
      phone,
      role,
      errors,
      successMsg,
      errorMsg,
      token,
      loading,
      password,
    } = this.state;

    const { isVisibleAdd, handleCloseAddContent } = this.props;

    return (
      <div>
        <div
          className={`right-side-popup right-side-popup-w75 ${
            isVisibleAdd ? "" : "right-side-popup-hide"
          }`}
        >
          <div
            className="right-side-popup-close"
            onClick={handleCloseAddContent}
          >
            <i className="fa-solid fa-angles-right"></i>
          </div>
          <div className="right-side-popup-inner">
            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex align-items-center mb-4">
                  <div className="bs-r-6 ps-3">
                    <h4 className="card-title mb-0">Add Admin Information</h4>
                    <p className="mb-0 tc-5">Add Admin</p>
                  </div>
                  <div className="ms-auto"> </div>
                </div>

                <form
                  className="form-area row align-items-center mb-5"
                  onSubmit={(e) => this.handleSubmit(e)}
                >
                  {errorMsg && (
                    <div className="alert alert-danger" role="alert">
                      {errorMsg}
                    </div>
                  )}

                  {successMsg && (
                    <div className="alert alert-success" role="alert">
                      {successMsg}
                    </div>
                  )}

                  {loading && (
                    <div className="d-flex justify-content-center align-items-center loader">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}

                  <div className="form-area1 row g-4">
                    <div className="col-lg-6">
                      <label htmlFor="name" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.name ? "is-invalid" : ""
                        }`}
                        id="name"
                        placeholder="Name"
                        name="name"
                        value={name}
                        onChange={this.handleInputChange}
                      />
                      {errors.name && (
                        <div className="invalid-feedback">{errors.name}</div>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.email ? "is-invalid" : ""
                        }`}
                        id="email"
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={this.handleInputChange}
                      />
                      {errors.name && (
                        <div className="invalid-feedback">{errors.email}</div>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="phone" className="form-label">
                        Phone
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.phone ? "is-invalid" : ""
                        }`}
                        id="phone"
                        placeholder="Phone"
                        name="phone"
                        value={phone}
                        onChange={this.handleInputChange}
                      />
                      {errors.name && (
                        <div className="invalid-feedback">{errors.phone}</div>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        className={`form-control ${
                          errors.password ? "is-invalid" : ""
                        }`}
                        id="password"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={this.handleInputChange}
                      />
                      {errors.name && (
                        <div className="invalid-feedback">
                          {errors.password}
                        </div>
                      )}
                    </div>

                    <div className="col-lg-12">
                      <label htmlFor="role" className="form-label">
                        Role
                      </label>
                      <select
                        className={`form-control ${
                          errors.role ? "is-invalid" : ""
                        }`}
                        id="role"
                        aria-label="Default select example"
                        value={role}
                        onChange={this.handleTypeChange}
                      >
                        <option value="">Select Role</option>
                        <option value="admin">Admin</option>
                        <option value="user">User</option>
                      </select>
                      {errors.role && (
                        <div className="invalid-feedback">{errors.role}</div>
                      )}
                    </div>
                    <div className="col-lg-12">
                      <button type="submit" className="btn-blue">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`right-side-popup-backdrop ${
            isVisibleAdd ? "" : "d-none"
          }`}
        ></div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});
export default connect(mapStatetoProps)(AddAdminUser);
